import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { menuItems } from '@commons/mobile-header/menu-items';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { SetMobileStateValue } from '@stores/mobile/mobile.actions';
import { MobileState } from '@stores/mobile/mobile.state';
import { Logout } from '@stores/session/session.actions';
import { SessionState } from '@stores/session/session.state';
import { MobileMenuModule, NotificationsModule } from '@wizbii-drive/commons';
import { Access, Accessorder, MobileMenuItem, Profile } from '@wizbii-drive/models';
import { MobileNotificationsService, Notification, NotificationKeyEnum } from '@wizbii-drive/services';
import { PaymentWebservice } from '@wizbii-drive/webservices';
import { DriveNotificationModule, slide } from '@wizbii-drive/widgets';
import { SvgIconModule } from '@wizbii/angular-ui';
import { WINDOW } from '@wizbii/angular-utilities';
import { trackEvent } from '@wizbii/tracking';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, take } from 'rxjs/operators';
import { NephRoutingEnum } from '@features/neph/neph-routing.enum';

enum SubMenuState {
  MobileMenu = 'mobile-menu',
  Notifications = 'notifications',
}

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slide()],
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  hasPayedAccess$ = new BehaviorSubject<boolean>(null);
  SubMenuState = SubMenuState;

  @Input() set hasPayedAccess(hasPayedAccess: boolean) {
    this.hasPayedAccess$.next(hasPayedAccess);
  }

  profile$ = new BehaviorSubject<Profile>(null);
  isNotificationsOpen$ = new BehaviorSubject<boolean>(false);
  isSubmenuOpen$ = new BehaviorSubject<SubMenuState | undefined>(undefined);
  displayNotificationsRedDot = true;

  currentPageTitle: string;

  @Input() set profile(profile: Profile) {
    this.profile$.next(profile);
  }

  @HostBinding('style.margin')
  marginBottom: string;

  @HostBinding('class.no-shadow')
  noShadow = false;

  @Select(MobileState.title)
  title$: Observable<string>;

  @Select(MobileState.subtitle)
  subtitle$: Observable<{ name: string; backLink: string | string[] }>;

  @Select(SessionState.accesses)
  accesses$: Observable<Access[]>;

  isDashboard$: Observable<boolean>;
  hasSponsoringCoupon$: Observable<boolean>;
  accessesOrders$: Observable<Accessorder[]>;

  FeaturesRoutingEnum = FeaturesRoutingEnum;
  menuItems: MobileMenuItem[] = [];
  lang = environment.lang;

  trackEvent = trackEvent;

  notifications$: Observable<Notification[]>;

  get nephURL(): string {
    return this.store.selectSnapshot(SessionState.hasPayedNeph)
      ? `/${FeaturesRoutingEnum.Neph}/${NephRoutingEnum.Status}`
      : `/${FeaturesRoutingEnum.Neph}`;
  }

  constructor(
    @Inject(WINDOW) private readonly window: any,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly router: Router,
    private readonly store: Store,
    readonly mobileNotificationsService: MobileNotificationsService,
    private readonly paymentWebservice: PaymentWebservice
  ) {
    this.notifications$ = this.mobileNotificationsService.notifications?.pipe(
      filter((notificationsObject) => !!notificationsObject),
      map((notificationsObject) => Object.values(notificationsObject)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isDashboard$ = this.router.events.pipe(
      startWith(new NavigationEnd(1, '/', '/')),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.window.location.pathname === '/'),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    combineLatest([
      this.hasPayedAccess$.pipe(
        filter((hasPayedAccess) => hasPayedAccess !== null),
        distinctUntilChanged()
      ),
      this.store.select(RouterState.state).pipe(
        map((state: any) => state.data),
        distinctUntilChanged()
      ),
    ]).subscribe({
      next: ([hasPayedAccess, data]) => {
        this.store.dispatch(new SetMobileStateValue('title', data.title));
        this.store.dispatch(new SetMobileStateValue('subtitle', data.subtitle));

        this.marginBottom = hasPayedAccess || !!data.hasTopNav ? '0' : '0 0 5.1875rem 0';
        this.noShadow = !!data.hasTopNav || !!data.hasTopNavWithMarginBottom;
      },
    });

    this.accessesOrders$ = combineLatest([
      this.paymentWebservice.findOrders().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true })),
      this.accesses$,
    ]).pipe(
      filter(([orders, accesses]) => !!accesses && !!orders),
      map(([orders, accesses]) => {
        return accesses.map((access) => {
          const orderAccess = orders.find((order) => access.orderId === order.id);

          return {
            order: orderAccess,
            access,
            hasAccess: access && access.accessEnd ? access.accessEnd * 1000 >= Date.now() : false,
          };
        });
      }),
      map((accessOrders) => accessOrders.filter((accessOrder) => accessOrder.hasAccess)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.profile$
      .pipe(
        filter((profile) => !!profile),
        take(1)
      )
      .subscribe({
        next: (profile) => {
          if (!this.hasPayedAccess$.value) {
            this.mobileNotificationsService.getSponsorshipNotifications(profile.id, this.lang);
          }
        },
      });

    this.mobileNotificationsService
      .getNotification(environment.urls.enVoitureSimoneNotificationUrl)
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit(): void {
    this.menuItems = [
      ...menuItems,
      {
        title: $localize`Gestion des cookies`,
        onClick: () => this.openCookies(),
      },
      {
        title: $localize`Déconnexion`,
        danger: true,
        onClick: () => this.logout(),
      },
    ];
    this.menuItems[4].link = this.nephURL;

    if (this.hasPayedAccess$.value === false) {
      this.menuItems.unshift({
        title: $localize`Voir les offres`,
        link: `/${FeaturesRoutingEnum.Offers}`,
        hasRedirection: false,
      });
    }
  }

  openCookies(): void {
    this.document.dispatchEvent(new CustomEvent<void>('WizbiiGdpr.toggleDialogVisibility'));
    this.trackEvent('Navigation', 'Click Gestion des cookies');
  }

  logout(): void {
    this.store.dispatch(new Logout());
    this.trackEvent('Navigation', 'Click Deconnexion');
  }

  openCloseSubMenu(subMenuState?: SubMenuState): void {
    if (subMenuState) {
      if (subMenuState === SubMenuState.Notifications) {
        this.displayNotificationsRedDot = false;
      }
      this.isSubmenuOpen$.next(this.isSubmenuOpen$.value === subMenuState ? undefined : subMenuState);

      if (this.isSubmenuOpen$.value) {
        this.currentPageTitle = this.store.selectSnapshot(MobileState.title);
        this.store.dispatch(
          new SetMobileStateValue(
            'title',
            subMenuState === SubMenuState.MobileMenu ? $localize`Menu` : $localize`Notifications`
          )
        );
      } else {
        this.store.dispatch(new SetMobileStateValue('title', this.currentPageTitle));
      }
    } else {
      this.isSubmenuOpen$.next(undefined);
    }

    if (subMenuState === SubMenuState.Notifications) {
      this.trackEvent('Navigation', `Click Notifications`);
    } else if (subMenuState === SubMenuState.MobileMenu) {
      this.trackEvent('Navigation', `Click Ouverture Menu`);
    } else {
      this.trackEvent('Navigation', `Click Fermeture Menu`);
    }
  }

  handleClick(item: MobileMenuItem): void {
    this.trackEvent('Navigation', `Click ${item.title}`);

    if (item.onClick) {
      item.onClick();
    }
  }

  removeNotification(key: NotificationKeyEnum): void {
    this.mobileNotificationsService.removeNotification(key);

    this.notifications$.pipe(take(1)).subscribe({
      next: (notifications) => {
        if (notifications.length === 0) {
          this.isSubmenuOpen$.next(undefined);
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.hasPayedAccess$.complete();
    this.profile$.complete();
    this.isNotificationsOpen$.complete();
    this.isSubmenuOpen$.complete();
  }
}

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule, DriveNotificationModule, MobileMenuModule, NotificationsModule],
  exports: [MobileHeaderComponent],
  declarations: [MobileHeaderComponent],
})
export class MobileHeaderModule {}
