import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HasPayedNephGuard implements CanActivate {
  constructor(private readonly store: Store, private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => this.store.selectOnce(SessionState.hasPayedNeph)),
      map((hasPayedNeph) => {
        return hasPayedNeph ? true : this.router.parseUrl('/');
      })
    );
  }
}
