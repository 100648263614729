import type { Consent } from '@wizbii-drive/models';

type Consents = Record<string, Consent[]>;

export class SetConsents {
  static readonly type = '[SetConsents] Set Consents';
  constructor(public consents: Consents | { [key: string]: boolean } | Consent[]) {}
}

export class SetConsent {
  static readonly type = '[SetConsent] Set Consent';
  constructor(public consent: Consent) {}
}
