import { environment } from '@environment';
import {
  createBingPixelConfig,
  createFacebookPixelConfig,
  createGoogleAnalyticsConfig,
  createGoogleRetargetingConfig,
  createHotjarConfig,
  createJuguiPixelConfig,
  createSnapchatPixelConfig,
  createTiktokPixelConfig,
  createWizbiiAnalyticsConfig,
  createYahooPixelConfig,
  TrackingConfig,
} from '@wizbii/tracking';

export const trackingConfig: TrackingConfig = {
  uuidSetterDomain: `https://a.${environment.apiDomain}`,
  informationGroupKeys: ['technical'],

  consentWidget: {
    version: environment.platform !== 'prod' ? 'staging' : '',
    defaultValue: false,
    apiDomain: environment.apiDomain,
    enableTermsModal: true,
    locale: environment.lang,
    productId: `${environment.applicationId}`,
    productLabel: 'WIZBII Drive',
    labels: {},
    rejectAll: true,
  },

  trackers: {
    analytics_wizbii: createWizbiiAnalyticsConfig(
      environment.analytics.waTrackingId,
      `https://a.${environment.apiDomain}`
    ),
    analytics_google: createGoogleAnalyticsConfig(environment.analytics.gaTrackingId),
    marketing_google: createGoogleRetargetingConfig(environment.analytics.gaConversionId),
    analytics_yahoo: createYahooPixelConfig(environment.analytics.yahoo.projectId, environment.analytics.yahoo.pixelId),
    analytics_bing: createBingPixelConfig(environment.analytics.bing.pixelId),
    analytics_hotjar: createHotjarConfig({
      hjid: environment.analytics.hotjar.id,
      hjsv: environment.analytics.hotjar.version,
    }),
    analytics_facebook_pixel: createFacebookPixelConfig({
      appId: environment.analytics.facebookPixel.id,
      version: environment.analytics.facebookPixel.version,
      locale: environment.locale,
    }),
    analytics_snapchat_pixel: createSnapchatPixelConfig({
      appId: environment.analytics.snapchatPixel.id,
    }),
    analytics_tiktok_pixel: createTiktokPixelConfig(environment.analytics.tikTokId),
    marketing_jugui: createJuguiPixelConfig(environment.analytics.juguiOfferId),
  },
};
