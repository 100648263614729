import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetMobileStateValue } from '@stores/mobile/mobile.actions';

export type MobileStateModel = Record<string, any>;

const MobileStateToken = new StateToken<MobileStateModel>('mobile');

@State({
  name: MobileStateToken,
  defaults: {},
})
@Injectable()
export class MobileState {
  @Selector([MobileState])
  static title(state: MobileStateModel): string | null {
    return state['title'];
  }

  @Selector([MobileState])
  static subtitle(state: MobileStateModel): { name: string; backLink: string | string[] } | null {
    return state['subtitle'];
  }

  @Action(SetMobileStateValue)
  setQueryParamHidden(ctx: StateContext<MobileStateModel>, { key, value }: SetMobileStateValue): void {
    ctx.patchState({ [key]: value });
  }
}
