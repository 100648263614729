import type { NephObject } from '@wizbii-drive/models';

export interface NephStateModel {
  nephObject: NephObject;
  editMode: boolean;
}

export class SetNephObject {
  static readonly type = '[Neph] Set neph object';
  constructor(public nephObject?: NephObject) {}
}

export class SetEditMode {
  static readonly type = '[NEPH] set edit mode';
  constructor(public editMode: boolean = false) {}
}
