import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { BetaAccessGuard } from '@features/guards/beta-access/beta-access.guard';
import { ForbidAccessNotPayedGuard } from '@features/guards/forbid-access-not-payed/forbid-access-not-payed.guard';
import { InitGuard } from '@features/guards/init/init.guard';
import { MustBeConnectedGuard } from '@features/guards/must-be-connected/must-be-connected.guard';
import {
  DashboardLayoutComponent,
  DashboardLayoutModule,
} from '@features/layouts/dashboard-layout/dashboard-layout.component';
import { HasPayedNephGuard } from '@features/neph/guards/has-payed-neph/has-payed-neph.guard';
import { ViewerModule } from '@wizbii-drive/viewer';
import {
  ACCOUNT_API_CONFIG,
  AccountWebservice,
  BasketWebservice,
  CONTENT_API_CONFIG,
  ContentWebservice,
  LessonWebservice,
  NotificationWebservice,
  PaymentWebservice,
  PERMII_API_CONFIG,
  ProfileWebservice,
  SerieRunWebservice,
  SerieWebservice,
  SponsorshipWebservice,
  ThemeWebservice,
  UserResponseWebservice,
} from '@wizbii-drive/webservices';
import { NotFoundComponent } from '@wizbii-drive/widgets';
import { ALGOLIA_SERVICE_TOKEN } from '@wizbii/algolia';
import { IMAGINARY_PIPE_CONFIG } from '@wizbii/angular-utilities';
import {
  ACCOUNT_API_CONFIG as WIZBII_ACCOUNT_API_CONFIG,
  AccountWebservice as WizbiiAccountWebservice,
  AlgoliaWebservice,
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  CONTACT_API_CONFIG,
  ContactWebservice,
  CONTENT_API_CONFIG as WIZBII_CONTENT_API_CONFIG,
  ContentLegalWebservice,
  PLACE_API_CONFIG,
  SUGGESTIONS_API_CONFIG,
  SuggestionsWebservice,
} from '@wizbii/webservices';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [InitGuard],
        children: [
          {
            path: FeaturesRoutingEnum.Play,
            canActivate: [MustBeConnectedGuard],
            loadChildren: () => import('./play/play.module').then((m) => m.PlayModule),
          },
          {
            path: FeaturesRoutingEnum.Resume,
            canActivate: [MustBeConnectedGuard],
            loadChildren: () => import('./resume/resume.module').then((m) => m.ResumeModule),
          },
          {
            path: FeaturesRoutingEnum.Checkout,
            canActivate: [MustBeConnectedGuard],
            loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
          },
          {
            path: '',
            canActivate: [MustBeConnectedGuard],
            component: DashboardLayoutComponent,
            children: [
              {
                path: '',
                data: {
                  title: null,
                  subtitle: null,
                  displayBtnNewSerie: true,
                },
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
              },
              {
                path: FeaturesRoutingEnum.List,
                data: {
                  title: $localize`Toutes les séries`,
                  subtitle: null,
                  hasTopNav: true,
                },
                loadChildren: () => import('./list/list.module').then((m) => m.ListModule),
              },
              {
                path: FeaturesRoutingEnum.Result,
                data: {
                  title: null,
                  subtitle: null,
                },
                loadChildren: () => import('./result/result.module').then((m) => m.ResultModule),
              },
              {
                path: FeaturesRoutingEnum.Progress,
                data: {
                  title: $localize`Ma progression`,
                  subtitle: null,
                },
                canActivate: [ForbidAccessNotPayedGuard],
                loadChildren: () => import('./progress/progress.module').then((m) => m.ProgressRoutingModule),
              },
              {
                path: FeaturesRoutingEnum.Advices,
                data: {
                  title: $localize`Actualités et conseils`,
                  subtitle: null,
                },
                loadChildren: () => import('./advices/advices.module').then((m) => m.AdvicesModule),
              },
              {
                path: FeaturesRoutingEnum.Faq,
                data: {
                  title: $localize`Foire aux questions`,
                  subtitle: null,
                },
                loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
              },
              {
                path: FeaturesRoutingEnum.Settings,
                data: {
                  title: $localize`Mon compte`,
                  subtitle: null,
                  hasTopNav: true,
                },
                loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
              },
              {
                path: FeaturesRoutingEnum.Profile,
                data: {
                  title: $localize`Mon profil`,
                  subtitle: null,
                  hasTopNav: false,
                },
                loadChildren: () => import('./settings/profile/profile.module').then((m) => m.ProfileModule),
              },
              {
                path: FeaturesRoutingEnum.Formula,
                data: {
                  title: $localize`Mes factures`,
                  subtitle: null,
                  hasTopNav: false,
                },
                loadChildren: () => import('./settings/formula/formula.module').then((m) => m.FormulaModule),
              },
              {
                path: FeaturesRoutingEnum.Lessons,
                data: {
                  title: $localize`Cours thématiques`,
                  subtitle: null,
                },
                loadChildren: () => import('./lessons/lessons.module').then((m) => m.LessonsModule),
              },
              {
                path: FeaturesRoutingEnum.Lesson,
                data: {
                  title: $localize`Cours thématiques`,
                  subtitle: null,
                  hasTopNav: true,
                },
                loadChildren: () => import('./lesson/lesson.module').then((m) => m.LessonModule),
              },
              {
                path: FeaturesRoutingEnum.Offers,
                data: {
                  title: $localize`Toutes les offres`,
                  subtitle: null,
                },
                loadChildren: () => import('./offers/offers.module').then((m) => m.OffersModule),
              },
              {
                path: FeaturesRoutingEnum.Examen,
                data: {
                  title: $localize`Espace examen`,
                  subtitle: null,
                },
                loadChildren: () => import('./examen/examen.module').then((m) => m.ExamenModule),
              },
              {
                path: FeaturesRoutingEnum.Neph,
                data: {
                  title: $localize`Numéro NEPH`,
                  subtitle: null,
                },
                loadChildren: () => import('./neph-home/neph-home.module').then((m) => m.NephHomeModule),
              },
            ],
          },
          {
            path: FeaturesRoutingEnum.Neph,
            canActivate: [MustBeConnectedGuard, HasPayedNephGuard],
            loadChildren: () => import('./neph/neph.module').then((m) => m.NephModule),
          },
        ],
      },
      {
        path: '**',
        component: NotFoundComponent,
        canActivate: [InitGuard],
        data: { unhandled404: true },
      },
    ]),
    ViewerModule,
    DashboardLayoutModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [
    InitGuard,
    MustBeConnectedGuard,
    ForbidAccessNotPayedGuard,
    BetaAccessGuard,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    CityWebservice,
    {
      provide: SUGGESTIONS_API_CONFIG,
      useValue: {
        baseUrl: `${environment.api.suggestion}`,
      },
    },
    SuggestionsWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.account,
        appId: environment.applicationId,
        locale: environment.locale,
      },
    },
    AccountWebservice,

    {
      provide: PERMII_API_CONFIG,
      useValue: {
        baseUrl: environment.api.permii,
      },
    },
    WizbiiAccountWebservice,
    {
      provide: WIZBII_ACCOUNT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.account,
        locale: environment.locale,
        baseUrlHost: 'https://account.api.%host%',
      },
    },

    ProfileWebservice,
    SerieWebservice,
    SerieRunWebservice,
    UserResponseWebservice,
    ThemeWebservice,
    LessonWebservice,
    PaymentWebservice,
    BasketWebservice,
    SponsorshipWebservice,
    NotificationWebservice,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
    {
      provide: CONTACT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.contact,
      },
    },
    ContactWebservice,
    {
      provide: CONTENT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.content,
      },
    },
    ContentWebservice,
    {
      provide: WIZBII_CONTENT_API_CONFIG,
      useValue: {
        baseUrl: environment.api.content,
        locale: environment.locale,
      },
    },
    ContentLegalWebservice,
    {
      provide: IMAGINARY_PIPE_CONFIG,
      useValue: {
        imaginaryUrl: environment.api.imaginary,
      },
    },
    TransferState,
    AlgoliaWebservice,
    {
      provide: ALGOLIA_SERVICE_TOKEN,
      useValue: {
        applicationId: environment.algolia.applicationId,
        apiKey: environment.algolia.apiKey,
        options: {},
      },
    },
  ],
})
export class FeaturesModule {}
