import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@ngx-pwa/offline';
import { Observable } from 'rxjs';
import { HEADER_SKIP_REFRESH_TOKEN } from '@wizbii/jwt';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  constructor(private readonly network: Network) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.network.online
      ? next.handle(request)
      : next.handle(
          request.clone({
            setHeaders: { [HEADER_SKIP_REFRESH_TOKEN]: 'true' },
          })
        );
  }
}
