import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, NgModule } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { Select } from '@ngxs/store';
import { QueryParamsHiddenState } from '@stores/query-params-hidden/query-params-hidden.state';
import { SessionState } from '@stores/session/session.state';
import { CodeType, FullCoupon, OfferIds, Package, Prices, Profile } from '@wizbii-drive/models';
import { PaymentWebservice } from '@wizbii-drive/webservices';
import { SvgIconModule } from '@wizbii/angular-ui';
import { UserOverview } from '@wizbii/models';
import { trackEvent } from '@wizbii/tracking';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-offer-header',
  templateUrl: './offer-header.component.html',
  styleUrls: ['./offer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferHeaderComponent {
  @Select(SessionState.hasPayedAccess)
  hasPayedAccess$: Observable<boolean>;

  @Select(SessionState.profile)
  profile$: Observable<Profile>;

  @Select(SessionState.user)
  user$: Observable<UserOverview>;

  @Select(QueryParamsHiddenState.queryParam('coupon'))
  couponName$: Observable<string>;

  coupon$: Observable<FullCoupon>;
  couponPrice$: Observable<number>;
  couponBasePrice$: Observable<number>;

  lang = environment.lang;
  OfferIds = OfferIds;
  Package = Package;
  CodeType = CodeType;

  FeaturesRoutingEnum = FeaturesRoutingEnum;

  trackEvent = trackEvent;

  get nativeElement(): any {
    return this.elementRef ? this.elementRef.nativeElement : null;
  }

  constructor(
    public readonly elementRef: ElementRef,
    readonly paymentWebservice: PaymentWebservice,
    private readonly dialog: MatDialog
  ) {
    this.coupon$ = this.couponName$.pipe(
      filter((coupon) => !!coupon),
      switchMap((coupon) => {
        const couponTrimed = (coupon || '').trim();

        return couponTrimed ? this.paymentWebservice.getCouponPublic(couponTrimed) : EMPTY;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.couponBasePrice$ = this.coupon$.pipe(
      map((coupon) => {
        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1) {
          const itemSplit = coupon.restrictedToArticles[0].split('-');
          return Prices[itemSplit[0]][itemSplit[1]][itemSplit[2]];
        }

        return null;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.couponPrice$ = combineLatest([this.coupon$, this.couponBasePrice$]).pipe(
      map(([coupon, couponBasePrice]) => {
        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1 && coupon.amountOff) {
          return couponBasePrice - coupon.amountOff;
        }

        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1 && coupon.percentOff) {
          return couponBasePrice - Math.round(couponBasePrice * (coupon.percentOff / 100) * 100 + Number.EPSILON) / 100;
        }

        return null;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule, MatDialogModule],
  exports: [OfferHeaderComponent],
  declarations: [OfferHeaderComponent],
})
export class OfferHeaderModule {}
