import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ForbidAccessNotPayedGuard implements CanActivate {
  constructor(private readonly store: Store) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(SessionState.hasPayedAccess))
    );
  }
}
