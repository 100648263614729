import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Network } from '@ngx-pwa/offline';
import { Store } from '@ngxs/store';
import { Logout, RefreshTokensStore, SetTokens } from '@stores/session/session.actions';
import { SessionState } from '@stores/session/session.state';
import { AuthenticationWebservice } from '@wizbii/webservices';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { JwtServiceInterface, JwtTokens } from '@wizbii/jwt';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[];

  constructor(
    private readonly store: Store,
    private readonly authService: AuthenticationWebservice,
    private readonly network: Network
  ) {
    this.blacklistRoutes = [
      'https://storage.googleapis.com',
      new RegExp(`https://auth.${environment.apiDomain}(?!(/v1/user).*(/email|password|overview))`, 'i'),
    ];
  }

  getTokens(): Observable<JwtTokens> {
    return this.store.selectOnce(SessionState.tokens);
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    if (this.network.online) {
      return this.authService
        .refreshToken(tokens, { headers: { 'ngsw-bypass': 'true' } })
        .pipe(
          switchMap((newTokens) => this.store.dispatch(new RefreshTokensStore(newTokens)).pipe(map(() => newTokens)))
        );
    }

    return this.store.select(SessionState.tokens);
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.store.dispatch(new SetTokens(jwtTokens));
  }
}
