<div *ngIf="(displayNotif$ | async) === true; else notifClosed" class="notification-wrapper">
  <ng-container *ngIf="(isMobile$ | async) !== null">
    <div
      @enterLeaveAnim
      [@.disabled]="(isMobile$ | async) === true"
      *ngIf="displayType$ | async as displayType"
      class="notification"
    >
      <ng-container *ngIf="notificationContents[displayType] as content">
        <div class="notification__wrapper">
          <div class="notification__content">
            <h3 class="content__title">{{ content.title }}</h3>
            <p class="content__description">
              {{ content.description }} <span class="content__description__partner">{{ content.siteLabel }}</span
              >&nbsp;!
            </p>
          </div>

          <svg-icon
            *ngIf="(isMobile$ | async) === false"
            class="notification__icon"
            [icon]="content.icon.name"
            [width]="content.icon.width"
            [height]="content.icon.height"
          ></svg-icon>
        </div>

        <div class="notification__actions">
          <ng-container *ngIf="(isMobile$ | async) === true">
            <a
              class="btn-clean redirect-mobile"
              [href]="content.redirectUrl"
              target="_blank"
              rel="noopener noreferrer"
              (click)="trackEvent('Notification Partner', 'Click Redirect', content.siteLabel)"
              >{{ content.buttonMobileLabel }}</a
            >
            <button class="btn-clean" (click)="closeNotif(content.siteLabel)">
              <svg-icon icon="exp-cross" width="1.5em"></svg-icon>
            </button>
          </ng-container>

          <ng-container *ngIf="(isMobile$ | async) === false">
            <button class="btn-clean notification__actions__close" (click)="closeNotif(content.siteLabel)" i18n>
              Non merci
            </button>
            <a
              *ngIf="content.redirectUrl"
              class="btn-clean notification__actions__redirect"
              [href]="content.redirectUrl"
              [target]="content.externalRedirect ? '_blank' : ''"
              rel="noopener noreferrer"
              (click)="trackEvent('Notification Partner', 'Click Redirect', content.siteLabel)"
            >
              {{ content.buttonLabel }}

              <svg-icon class="redirect__icon" icon="exp-arrow-right" width="1.5em"></svg-icon>
            </a>

            <a
              *ngIf="content.routerLink"
              class="btn-clean notification__actions__redirect"
              [routerLink]="content.routerLink"
              (click)="trackEvent('Notification Partner', 'Click Redirect', content.siteLabel)"
            >
              {{ content.buttonLabel }}

              <svg-icon class="redirect__icon" icon="exp-arrow-right" width="1.5em"></svg-icon>
            </a>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #notifClosed>
  <ng-container *ngIf="(isMobile$ | async) === false">
    <div *ngIf="displayType$ | async as displayType" class="notification-wrapper notification-wrapper--closed">
      <button
        @enterLeaveAnim
        *ngIf="notificationContents[displayType] as content"
        class="btn-clean notification-closed"
        (click)="openNotif(content.siteLabel)"
      >
        <svg-icon
          *ngIf="content.iconClosed"
          [icon]="content.iconClosed.name"
          [width]="content.iconClosed.width"
          [height]="content.iconClosed.height"
        ></svg-icon>
      </button>
    </div>
  </ng-container>
</ng-template>
