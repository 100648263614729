import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { SetConsents } from '@stores/consents/consents.actions';
import { QueryParamsHiddenState } from '@stores/query-params-hidden/query-params-hidden.state';
import {
  AuthFailed,
  AuthSuccess,
  Init,
  Logout,
  RefreshTokensStore,
  RemoveTokens,
  SetIdentityCard,
  SetProfile,
  SetProvider,
  SetTokens,
  SetUser,
} from '@stores/session/session.actions';
import { UpdateThemes } from '@stores/themes/themes.actions';
import { OtherPackage, Package } from '@wizbii-drive/models';
import { ConsentWebservice, PaymentWebservice, ProfileWebservice } from '@wizbii-drive/webservices';
import { WINDOW } from '@wizbii/angular-utilities';
import { deserializeJwt, JwtTokens } from '@wizbii/jwt';
import { AccountWebservice as WizbiiAccountWebservice, AuthenticationWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import qs from 'qs';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import type { Profile } from '@wizbii-drive/models';
import type { IdentityCard, UserOverview } from '@wizbii/models';
import type { NgxsOnInit } from '@ngxs/store';
import type { Access } from '@wizbii-drive/models';

export enum SessionStatusEnum {
  Logged = 'LOGGED',
  NotLogged = 'NOT LOGGED',
}

export class SessionStateModel {
  tokens: JwtTokens;
  status: SessionStatusEnum;
  user: UserOverview;
  profile: Profile;
  identityCard: IdentityCard;
  provider: string;
}

export interface ConnectionInfo {
  provider: string;
  identifier: string;
  name: string;
  uniqUserId?: string;
  userId?: string;
  date: Date | string;
}

const SessionStateToken = new StateToken<SessionStateModel>('session');

export enum SessionErrorMessage {
  badCredential = 'BadCredentialsException',
  emailAlreadyUsed = 'EmailAlreadyUsedException',
}

const defaultState: SessionStateModel = {
  tokens: null,
  status: null,
  user: null,
  profile: null,
  identityCard: null,
  provider: null,
};

@UntilDestroy()
@State({
  name: SessionStateToken,
  defaults: defaultState,
})
@Injectable()
export class SessionState implements NgxsOnInit {
  static onceProfile = false;
  static readonly TOKEN_KEY = 'wizbii_tokens';
  static readonly EXPIRY_KEY = 'wizbii_tokens_expiry';
  static readonly OLD_TOKEN_KEY = `${environment.applicationId}_tokens`;
  static readonly OLD_EXPIRY_KEY = `${environment.applicationId}_expiry`;
  private accountLogout: string;

  @Selector([SessionStateToken])
  static tokens(state: SessionStateModel): JwtTokens {
    return state.tokens;
  }

  @Selector([SessionStateToken])
  static provider(state: SessionStateModel): string {
    return state.provider;
  }

  @Selector([SessionStateToken])
  static info(state: SessionStateModel): { 'external-services-package-id': string; 'user-id': string } {
    return state && state.tokens && state.tokens.token ? JSON.parse(atob(state.tokens.token.split('.')[1])) : undefined;
  }

  @Selector([SessionStateToken])
  static hasTokens(state: SessionStateModel): boolean {
    return !!state.tokens;
  }

  @Selector([SessionStateToken])
  static user(state: SessionStateModel): UserOverview {
    return state.user;
  }

  @Selector([SessionStateToken])
  static userId(state: SessionStateModel): string {
    return deserializeJwt(state.tokens.token)['user-id'];
  }

  @Selector([SessionStateToken])
  static profile(state: SessionStateModel): Profile {
    return state.profile;
  }

  @Selector([SessionStateToken])
  static identityCard(state: SessionStateModel): IdentityCard {
    return state.identityCard;
  }

  @Selector([SessionStateToken])
  static status(state: SessionStateModel): SessionStatusEnum {
    return state.status;
  }

  @Selector([SessionStateToken])
  static isInitialized(state: SessionStateModel): boolean {
    return state.status !== null;
  }

  @Selector([SessionStateToken])
  static isLogged(state: SessionStateModel): boolean {
    return state.status === SessionStatusEnum.Logged;
  }

  @Selector([SessionStateToken])
  static isNotLogged(state: SessionStateModel): boolean {
    return state.status === SessionStatusEnum.NotLogged;
  }

  @Selector([SessionState.profile])
  static fullName(profile: Profile): string {
    return `${profile.firstName} ${profile.lastName}`;
  }

  @Selector([SessionState.profile])
  static firstName(profile: Profile): string {
    return profile.firstName;
  }

  @Selector([SessionState.profile])
  static accesses(profile: Profile): Access[] {
    return profile.accesses;
  }

  @Selector([SessionState.profile])
  static neph(profile: Profile): string {
    return profile.neph;
  }

  @Selector([SessionState.profile, SessionState.user])
  static baseInfo(profile: Profile, user: UserOverview): { email: string; firstName: string; lastName: string } {
    return { email: user.username, firstName: profile.firstName, lastName: profile.lastName };
  }

  @Selector([SessionStateToken])
  static hasPayedFullAccess(state: SessionStateModel): boolean {
    return !!(
      state.profile &&
      state.profile.accesses &&
      state.profile.accesses.length > 0 &&
      !!state.profile.accesses.some(
        (access) =>
          (access.packageId.includes(Package.Full) ||
            access.packageId.includes(OtherPackage.Loyalty) ||
            access.packageId.includes(OtherPackage.Partner) ||
            access.packageId.includes(OtherPackage.Integral) ||
            access.packageId.includes(OtherPackage.Reference)) &&
          access.accessEnd * 1000 >= Date.now()
      )
    );
  }

  @Selector([SessionStateToken])
  static hasPayedNeph(state: SessionStateModel): boolean {
    return !!state?.profile?.accesses?.some((access) => access.packageId.includes(Package.NephSubmission));
  }

  @Selector([SessionStateToken])
  static hasPayedLaPosteExam(state: SessionStateModel): boolean {
    return !!state?.profile?.accesses?.some((access) => access.packageId.includes(Package.LaPoste));
  }

  @Selector([SessionStateToken])
  static hasPayedAccess(state: SessionStateModel): boolean {
    return !!(
      state.profile &&
      state.profile.accesses &&
      state.profile.accesses.length > 0 &&
      state.profile.accesses.some((access) => access.accessEnd * 1000 >= Date.now())
    );
  }

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authWebservice: AuthenticationWebservice,
    private readonly wizbiiAccountWebservice: WizbiiAccountWebservice,
    private readonly profileWebservice: ProfileWebservice,
    private readonly cookieService: CookieService,
    private readonly consentWebservice: ConsentWebservice,
    private readonly paymentWebservice: PaymentWebservice,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(WINDOW) private readonly window: any
  ) {}

  ngxsOnInit(): void {
    this.accountLogout = `${environment.urls.account.logout}${environment.urls.drivePublic}`;

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('provider')),
        filter((provider) => !!provider),
        untilDestroyed(this)
      )
      .subscribe((provider) => {
        this.store.dispatch(new SetProvider(provider));
      });
  }

  @Action(Init)
  // eslint-disable-next-line sonarjs/cognitive-complexity
  init(ctx: StateContext<SessionStateModel>, { tokens, fromGuard }: Init): any {
    const realTokens = tokens ? tokens : this.readTokens();

    let hasDriveFreetag = false;

    if (realTokens) {
      ctx.patchState({ tokens: realTokens });

      const userId = deserializeJwt(realTokens.token)['user-id'];

      return this.authWebservice.getUserOverview(userId).pipe(
        tap((user) => ctx.patchState({ user })),
        tap((user) => (hasDriveFreetag = !!user && user['tags']?.findIndex((tag) => tag === 'drive-free') > -1)),
        switchMap(() => this.profileWebservice.get(userId)),
        tap(() => ctx.dispatch(new UpdateThemes())),
        tap((profile) => ctx.patchState({ profile })),
        switchMap((profile) => {
          return hasDriveFreetag && !profile.accesses.some((access) => access.packageId.includes(OtherPackage.Partner))
            ? this.paymentWebservice.addFreeAccess()
            : of({});
        }),
        switchMap(() => this.consentWebservice.getAll(userId, realTokens.token)),
        tap((consents) => {
          if (consents) {
            return ctx.dispatch(new SetConsents(consents));
          }
        }),
        tap(() => ctx.patchState({ status: SessionStatusEnum.Logged })),
        tap(() => this.persistLastConnection(userId)),

        catchError((error) => {
          if (
            error.status === 404 &&
            error.url.includes('permii.api') &&
            error.url.includes('/v1/profile/') &&
            fromGuard &&
            !SessionState.onceProfile
          ) {
            SessionState.onceProfile = true;

            return this.wizbiiAccountWebservice.getIdentityCard(userId).pipe(
              tap((identityCard) => ctx.patchState({ identityCard })),
              tap(() => ctx.patchState({ status: SessionStatusEnum.Logged })),
              tap(() => this.persistLastConnection(userId)),
              switchMap(() => {
                const queryParams = {
                  ...QueryParamsHiddenState.getRequiredParams(
                    this.router.parseUrl(`${this.window.location.pathname}${this.window.location.search}`).queryParams
                  ),
                  'ngsw-bypass': 'true',
                };
                const queryParamsStr = qs.stringify(queryParams);

                const redirect = `${environment.urls.driveApp}${this.window.location.pathname}?${queryParamsStr}`;
                const queryParamsAccount = {
                  ...QueryParamsHiddenState.getAccountRequiredParams(
                    this.router.parseUrl(`${this.window.location.pathname}${this.window.location.search}`).queryParams
                  ),
                  'app-id': environment.applicationId,
                  redirect,
                };
                const queryParamsAccountStr = qs.stringify(queryParamsAccount);

                this.window.open(`${environment.urls.account.activationBase}?${queryParamsAccountStr}`, '_self');

                return of({});
              }),
              catchError(() => {
                return ctx.dispatch(new Logout());
              })
            );
          }

          return throwError(error);
        })
      );
    }

    const uuid = this.cookieService.get('uniqUserId');

    return this.consentWebservice.getAll(uuid).pipe(
      // eslint-disable-next-line sonarjs/no-identical-functions
      tap((consents) => {
        if (consents) {
          return ctx.dispatch(new SetConsents(consents));
        }
      }),
      tap(() => ctx.patchState({ status: SessionStatusEnum.NotLogged }))
    );
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<SessionStateModel>, { tokens }: RefreshTokensStore): void {
    ctx.patchState({ tokens });
  }

  @Action(SetProfile)
  setProfile(ctx: StateContext<SessionStateModel>, { profile }: SetProfile): void {
    ctx.patchState({ profile });
  }

  @Action(SetProvider)
  setProvider(ctx: StateContext<SessionStateModel>, { provider }: SetProvider): void {
    ctx.patchState({ provider });
  }

  @Action(SetIdentityCard)
  setIdentityCard(ctx: StateContext<SessionStateModel>, { identityCard }: SetIdentityCard): void {
    ctx.patchState({ identityCard });
  }

  @Action(SetUser)
  setUser(ctx: StateContext<SessionStateModel>, { user }: SetUser): void {
    ctx.patchState({ user });
  }

  @Action(AuthSuccess)
  authSuccess(ctx: StateContext<SessionStateModel>, { tokens }: AuthSuccess): Observable<void> {
    this.writeTokens(tokens);

    ctx.patchState({
      status: SessionStatusEnum.Logged,
      tokens,
    });

    return ctx.dispatch(new Init(tokens, false));
  }

  @Action(AuthFailed)
  authFailed(ctx: StateContext<SessionStateModel>): void {
    this.forgetTokens();

    ctx.patchState({
      user: null,
      profile: null,
      tokens: null,
      status: SessionStatusEnum.NotLogged,
    });
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<SessionStateModel>, { tokens }: SetTokens): void {
    this.writeTokens(tokens);

    ctx.patchState({
      tokens,
    });
  }

  @Action(RemoveTokens)
  removeTokens(ctx: StateContext<SessionStateModel>): void {
    this.forgetTokens();

    ctx.patchState({
      tokens: null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<SessionStateModel>): void {
    ctx.setState(defaultState);
    this.window.open(this.accountLogout, '_self');
  }

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(SessionState.TOKEN_KEY) || 'null');
    const oldRawTokens = JSON.parse(this.cookieService.get(SessionState.OLD_TOKEN_KEY) || 'null');

    return rawTokens ? rawTokens : oldRawTokens ? oldRawTokens : null;
  }

  private writeTokens(tokens: JwtTokens): void {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(SessionState.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(SessionState.EXPIRY_KEY))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        SessionState.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      SessionState.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens() {
    const cookieDomain = this.getCookieDomain();
    const forgetDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(
      SessionState.TOKEN_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      SessionState.EXPIRY_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      SessionState.OLD_TOKEN_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );

    this.cookieService.set(
      SessionState.OLD_EXPIRY_KEY,
      '',
      forgetDate,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }

  persistLastConnection(userId: string): void {
    const lastConnectionCookieName = 'last-connection';
    const lastConnectionCookieExpiryName = 'last-connection-expiry';
    const token = JSON.parse(this.cookieService.get('wizbii_tokens')).token;
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(lastConnectionCookieExpiryName);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(lastConnectionCookieExpiryName))
      : new Date(Date.now() + msIn390Days);

    combineLatest([
      this.store.select(SessionState.profile).pipe(
        filter((profile: Profile): profile is Profile => !!profile),
        take(1)
      ),
      this.store.select(SessionState.user).pipe(
        filter((user): user is UserOverview => !!user),
        take(1)
      ),
      this.store.select(SessionState.provider).pipe(
        filter((provider): provider is string => !!provider),
        take(1)
      ),
    ]).subscribe({
      next: ([profile, user, provider]: [Profile, UserOverview, string]) => {
        const lastConnection: ConnectionInfo = {
          provider,
          identifier: user.username,
          name: `${profile.firstName} ${profile.lastName}`,
          uniqUserId: undefined,
          userId: userId ? userId : deserializeJwt(token)['user-id'],
          date: new Date(),
        };

        if (!expiryExists) {
          this.cookieService.set(
            lastConnectionCookieExpiryName,
            expiry.getTime().toString(),
            expiry,
            '/',
            cookieDomain,
            cookieDomain !== 'localhost',
            cookieDomain === 'localhost' ? 'Lax' : 'None'
          );
        }

        this.cookieService.set(
          lastConnectionCookieName,
          JSON.stringify(lastConnection),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      },
    });
  }
}
