export class SetQueryParamHidden {
  static readonly type = '[QueryParamsHidden] Set';
  constructor(public key: string, public value?: string) {}
}

export class SetMultipleQueryParamHidden {
  static readonly type = '[QueryParamsHidden] Set Multiple';
  constructor(public queryParams: { [key: string]: string }) {}
}

export class RemoveMultipleQueryParamHidden {
  static readonly type = '[QueryParamsHidden] Set Multiple';
  constructor(public containKey: string) {}
}
