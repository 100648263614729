import type { HttpErrorResponse } from '@angular/common/http';
import type { Profile } from '@wizbii-drive/models';
import type { IdentityCard, UserOverview } from '@wizbii/models';
import type { JwtTokens } from '@wizbii/jwt';

export abstract class SessionFailed {
  static readonly type;
  constructor(public error: HttpErrorResponse | Error) {}
}

export class Init {
  static readonly type = '[Session] Init';
  constructor(public tokens?: JwtTokens, public fromGuard = true) {}
}

export class AuthSuccess {
  static readonly type = '[Session] Auth Success';
  constructor(public tokens: JwtTokens) {}
}

export class AuthFailed {
  static readonly type = '[Session] Auth Failed';
}

export class SetProfile {
  static readonly type = '[Session] Set Profile';
  constructor(public profile: Profile) {}
}

export class SetIdentityCard {
  static readonly type = '[Session] Set IdentityCard';
  constructor(public identityCard: IdentityCard) {}
}

export class SetUser {
  static readonly type = '[Session] Set User';
  constructor(public user: UserOverview) {}
}

export class RefreshTokensStore {
  static readonly type = '[Session] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Session] Logout';
}

export class SetTokens {
  static readonly type = '[Session] Set Tokens';
  constructor(public tokens: JwtTokens) {}
}

export class RemoveTokens {
  static readonly type = '[Session] Remove Tokens';
}

export class SetProvider {
  static readonly type = '[Session] SetProvider';
  constructor(public provider: string) {}
}
