<p *ngIf="(coupon$ | async) === null" class="text">
  <ng-container i18n>Débloquez toutes les séries à partir de</ng-container>
  <span class="price">
    {{ 9.9 | currency: 'EUR':'symbol':'1.2-2':lang }}
  </span>
</p>

<p *ngIf="coupon$ | async as coupon" class="text">
  <ng-container i18n>Débloquez toutes les séries avec votre code promo à</ng-container>
  <span class="price">
    <ng-container *ngIf="(coupon.amountOff && (couponPrice$ | async) !== null) || coupon.percentOff">{{
      couponPrice$ | async | currency: 'EUR':'symbol':'1.2-2':lang
    }}</ng-container>
    <ng-container *ngIf="coupon.amountOff && (couponPrice$ | async) === null">-{{ coupon.amountOff }}€</ng-container>
    <ng-container *ngIf="coupon.percentOff && (couponPrice$ | async) === null">-{{ coupon.percentOff }}%</ng-container>
  </span>
</p>

<ng-container *ngIf="coupon$ | async as coupon">
  <a
    class="btn-clean btn-offer"
    [routerLink]="
      coupon.restrictedToArticles[0]
        ? ['/', FeaturesRoutingEnum.Offers, coupon.restrictedToArticles[0]]
        : ['/', FeaturesRoutingEnum.Offers, OfferIds[lang][CodeType.Car][Package.Full]]
    "
    [queryParams]="{ coupon: coupon.id }"
    (click)="trackEvent('Navigation', 'Click Offers')"
  >
    <span i18n>Je me lance</span>
    <svg-icon class="btn-offer__icon" icon="arrow-down" width=".67em"></svg-icon>
  </a>
</ng-container>

<ng-container *ngIf="(coupon$ | async) === null">
  <a
    class="btn-clean btn-offer"
    [routerLink]="['/', FeaturesRoutingEnum.Offers]"
    (click)="trackEvent('Navigation', 'Click Offers')"
  >
    <span i18n>Je me lance</span>
    <svg-icon class="btn-offer__icon" icon="arrow-down" width=".67em"></svg-icon>
  </a>
</ng-container>
