import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class BetaAccessGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (route.queryParamMap.get('access') !== 'J9xIPq3lHUmUXDd5q') {
      return this.router.parseUrl('/');
    }
    return true;
  }
}
