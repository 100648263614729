<app-offer-header *ngIf="(hasPayedAccess$ | async) === false"></app-offer-header>

<app-notification
  *ngIf="(isMobile$ | async) === false && seriesOfficialFinished$ | async as seriesOfficialFinished"
  [seriesOfficialFinished]="seriesOfficialFinished"
  [accessOrders]="accessesOrders$ | async"
  [profile]="profile$ | async"
></app-notification>

<ng-container *ngIf="platform$ | async as platform">
  <mat-sidenav-container
    *ngIf="platform === 'web' || platform === 'tablet'; else mobile"
    class="container"
    [class.container--free]="(hasPayedAccess$ | async) === false"
    autosize
  >
    <mat-sidenav class="sidenav" [attr.platform]="platform" mode="side" opened [fixedInViewport]="false">
      <a class="btn-clean sidenav__logo" routerLink="/">
        <svg-icon *ngIf="platform === 'web'" icon="logo" width="6.9375em" height="2.375em"></svg-icon>

        <svg-icon *ngIf="platform === 'tablet'" icon="logo-mini" width="2.625em" height="1.75em"></svg-icon>
      </a>

      <mat-nav-list class="sidenav__links">
        <nav aria-label="Navigation du site" i18n-aria-label>
          <a
            class="sidenav__link"
            mat-list-item
            [routerLink]="['/']"
            routerLinkActive="sidenav__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <svg-icon class="sidenav__link__icon" icon="exp-home" width="1.5em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.dashboard">Accueil</h4>
          </a>

          <a
            class="sidenav__link"
            [class.sidenav__link--active2]="rlaSeeOffers.isActive"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.Offers]"
            routerLinkActive="sidenav__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="trackEvent('Navigation', 'Click Toutes les offres')"
            *ngIf="(hasPayedAccess$ | async) === false"
          >
            <svg-icon
              class="sidenav__link__icon"
              icon="star-offers"
              width="1.625em"
              height="1.3125em"
              mat-list-icon
            ></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n>Voir les offres</h4>
          </a>

          <!-- It's usefull to make this to avoid write TS to activate "Séries" item -->
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Offers]"
            style="display: none"
            routerLinkActive
            #rlaSeeOffers="routerLinkActive"
          >
            Les offres
          </a>

          <a
            class="sidenav__link"
            [class.sidenav__link--active2]="rlaLesson.isActive"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.Lessons]"
            routerLinkActive="sidenav__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="trackEvent('Navigation', 'Click Cours')"
          >
            <svg-icon
              class="sidenav__link__icon"
              icon="book"
              width="1.625em"
              height="1.3125em"
              mat-list-icon
            ></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.course">Cours</h4>
          </a>

          <!-- It's usefull to make this to avoid write TS to activate "Séries" item -->
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Lesson]"
            style="display: none"
            routerLinkActive
            #rlaLesson="routerLinkActive"
          >
            Un cours
          </a>

          <a
            class="sidenav__link"
            [class.sidenav__link--active2]="rlaResult.isActive"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.List]"
            routerLinkActive="sidenav__link--active"
            (click)="trackEvent('Navigation', 'Click Séries')"
          >
            <svg-icon class="sidenav__link__icon" icon="list" width="1.5em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.series">Séries</h4>
          </a>

          <!-- It's usefull to make this to avoid write TS to activate "Séries" item -->
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Result]"
            style="display: none"
            routerLinkActive
            #rlaResult="routerLinkActive"
          >
            Résultat
          </a>

          <a
            *ngIf="(hasPayedAccess$ | async) === true"
            class="sidenav__link"
            [routerLink]="['/', FeaturesRoutingEnum.Progress]"
            [routerLinkActive]="(hasPayedAccess$ | async) === true ? 'sidenav__link--active' : ''"
            mat-list-item
            (click)="trackEvent('Navigation', 'Click Progression')"
          >
            <svg-icon class="sidenav__link__icon" icon="stat" width="1.375em" height="1.625em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.progress">
              Ma progression
            </h4>
          </a>

          <a
            class="sidenav__link"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.Advices]"
            routerLinkActive="sidenav__link--active"
            (click)="trackEvent('Navigation', 'Click Conseils')"
          >
            <svg-icon class="sidenav__link__icon" icon="exp-information" width="1.625em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.advices">Conseils</h4>
          </a>

          <!-- <a
            class="sidenav__link"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.Examen]"
            routerLinkActive="sidenav__link--active"
            (click)="trackEvent('Navigation', 'Click Examen')"
          >
            <svg-icon class="sidenav__link__icon" icon="certificate" width="1.625em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n>Espace examen</h4>
          </a> -->

          <!-- It's usefull to make this to avoid write TS to activate "Espace Examen" item
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Examen]"
            style="display: none"
            routerLinkActive
            #rlaResult="routerLinkActive"
          >
            Espace examen
          </a> -->

          <!-- <ng-container *ngIf="shouldShowNephEntry">
            <a
              class="sidenav__link"
              mat-list-item
              [routerLink]="nephURL"
              routerLinkActive="sidenav__link--active"
              (click)="trackEvent('Navigation', 'Click NEPH')"
            >
              <svg-icon class="sidenav__link__icon" icon="document" width="1.625em" mat-list-icon></svg-icon>

              <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n>Numéro NEPH</h4>
            </a>

            It's usefull to make this to avoid write TS to activate "Permis de conduire" item
            <a [routerLink]="nephURL" style="display: none" routerLinkActive #rlaResult="routerLinkActive">
              Numéro NEPH
            </a>
          </ng-container> -->

          <a
            class="sidenav__link"
            mat-list-item
            [routerLink]="['/', FeaturesRoutingEnum.Settings]"
            routerLinkActive="sidenav__link--active"
            (click)="trackEvent('Navigation', 'Click Mon Compte')"
          >
            <svg-icon class="sidenav__link__icon" icon="exp-account" width="1.625em" mat-list-icon></svg-icon>

            <h4 *ngIf="platform === 'web'" class="sidenav__link__text" mat-line i18n="@@title.settings">Mon compte</h4>
          </a>
        </nav>
      </mat-nav-list>

      <button
        *ngIf="platform === 'web'"
        type="button"
        class="btn-clean sidenav__logout"
        (click)="logout()"
        i18n="@@actions.logout"
      >
        Déconnexion
      </button>

      <button *ngIf="platform === 'tablet'" type="button" class="btn-clean sidenav__logout" (click)="logout()">
        <span class="visually-hidden" i18n="@@actions.logout">Déconnexion</span>
        <svg-icon *ngIf="platform === 'tablet'" icon="quit" width="1.625em" height="1.6875em"></svg-icon>
      </button>
    </mat-sidenav>

    <mat-sidenav-content class="content" [attr.platform]="platform">
      <div style="min-height: 100%">
        <router-outlet></router-outlet>
      </div>

      <wiz-footer
        class="footer"
        [attr.appid]="appId"
        [attr.contactemailkey]="contactEmailKey"
        [attr.apidomain]="apiDomain"
        [attr.hascgv]="true"
        [attr.haspromotions]="false"
        [attr.profilefirstname]="contactProfile?.firstName"
        [attr.profilelastname]="contactProfile?.lastName"
        [attr.profileemail]="contactProfile?.email"
        [attr.profilephone]="contactProfile?.phone"
        [attr.currentlocale]="currentLocale"
      >
        <span slot="wzbFooterListInside">
          <li>
            <a [routerLink]="['/', FeaturesRoutingEnum.Lessons]" (click)="trackEvent('Footer', 'Click Cours')" i18n
              >Cours</a
            >
          </li>

          <li>
            <a
              [routerLink]="['/', FeaturesRoutingEnum.List, 'series', 'official']"
              (click)="trackEvent('Footer', 'Click Série')"
              i18n
              >Séries</a
            >
          </li>

          <li>
            <a
              [routerLink]="['/', FeaturesRoutingEnum.List, SeriesRoutingEnum.Themes]"
              (click)="trackEvent('Footer', 'Click Série Thématiques')"
              i18n
              >Séries Thématiques</a
            >
          </li>
        </span>
      </wiz-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-template #mobile>
    <app-mobile-header
      [profile]="profile$ | async"
      [hasPayedAccess]="hasPayedAccess$ | async"
      [style.top.rem]="offersHeaderRemHeight$ | async"
    ></app-mobile-header>

    <div [style.paddingBottom.vw]="26">
      <router-outlet></router-outlet>
    </div>

    <div *ngIf="(displayBtnNewSerie$ | async) === true" class="sidenav-mobile__new-container">
      <button type="button" class="btn-clean sidenav-mobile__new" [@bounce]="displayModal" (click)="toggleModal()">
        <svg-icon
          class="sidenav-mobile__new__icon"
          [class.sidenav-mobile__new__icon--open]="displayModal"
          icon="exp-plus"
          width="3.0625em"
          height="3.0625em"
        ></svg-icon>
      </button>

      <div
        *ngIf="displayModal"
        (click)="toggleModal()"
        [@fadeInUpOnEnter]
        [@fadeOutDownOnLeave]
        class="new-serie-mobile-panel"
      >
        <button
          *ngIf="(hasPayedAccess$ | async) === false && (serieFree$ | async) && (serieFree$ | async).status === 'new'"
          type="button"
          class="btn-clean new-serie-mobile-panel__item"
          (click)="startNewSerie('exam')"
          i18n
        >
          Tester une série
        </button>

        <button
          *ngIf="(hasPayedAccess$ | async) === false && (serieFree$ | async) && (serieFree$ | async).status !== 'new'"
          type="button"
          class="btn-clean new-serie-mobile-panel__item"
          [routerLink]="['/', FeaturesRoutingEnum.Offers]"
          i18n
        >
          Lancer la série N°2
        </button>

        <button
          *ngIf="(hasPayedAccess$ | async) === true"
          class="btn-clean new-serie-mobile-panel__item"
          type="button"
          (click)="startNewSerie('exam')"
          role="menuitem"
          i18n="@@serie.exam.start"
        >
          Commencer une série en examen
        </button>
        <button
          *ngIf="(hasPayedAccess$ | async) === true"
          class="btn-clean new-serie-mobile-panel__item"
          type="button"
          (click)="startNewSerie('training')"
          role="menuitem"
          i18n="@@serie.training.start"
        >
          Commencer une série en entraînement
        </button>
      </div>
    </div>

    <mat-toolbar class="sidenav-mobile">
      <mat-toolbar-row class="sidenav-mobile__row">
        <nav class="sidenav-mobile__nav" aria-label="Navigation du site mobile" i18n-aria-label>
          <a
            class="sidenav-mobile__link"
            [routerLink]="['/']"
            routerLinkActive="sidenav-mobile__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="trackEvent('Navigation', 'Click Dashboard')"
          >
            <svg-icon class="sidenav-mobile__link__icon" icon="exp-home" width="1.25em" height="1.25em"></svg-icon>
          </a>

          <a
            class="sidenav-mobile__link"
            [routerLink]="['/', FeaturesRoutingEnum.Lessons]"
            [class.sidenav-mobile__link--active2]="rlaLessonMobile.isActive"
            routerLinkActive="sidenav-mobile__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="trackEvent('Navigation', 'Click Cours')"
          >
            <svg-icon class="sidenav-mobile__link__icon" icon="book" width="1.25em" height="1.25em"></svg-icon>
          </a>

          <!-- It's usefull to make this to avoid write TS to activate "Séries" item -->
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Lesson]"
            style="display: none"
            routerLinkActive
            #rlaLessonMobile="routerLinkActive"
          >
            Un cours
          </a>

          <a
            class="sidenav-mobile__link"
            [class.sidenav-mobile__link--active2]="rlaResultMobile.isActive"
            [routerLink]="['/', FeaturesRoutingEnum.List]"
            routerLinkActive="sidenav-mobile__link--active"
            (click)="trackEvent('Navigation', 'Click Séries')"
          >
            <svg-icon class="sidenav-mobile__link__icon" icon="list" width="1.25em" height="1.25em"></svg-icon>
          </a>

          <!-- It's usefull to make this to avoid write TS to activate "Séries" item -->
          <a
            [routerLink]="['/', FeaturesRoutingEnum.Result]"
            style="display: none"
            routerLinkActive
            #rlaResultMobile="routerLinkActive"
          >
            Résultat
          </a>

          <a
            *ngIf="(hasPayedAccess$ | async) === false"
            class="sidenav-mobile__link sidenav-mobile__link__offers"
            [routerLink]="['/', FeaturesRoutingEnum.Offers]"
            (click)="trackEvent('Navigation', 'Click Toutes les offres')"
          >
            <svg-icon class="sidenav-mobile__link__icon" icon="star-offers" width="1.25em" height="1.25em"></svg-icon>
          </a>

          <a
            *ngIf="(hasPayedAccess$ | async) === true"
            class="sidenav-mobile__link"
            [routerLink]="['/', FeaturesRoutingEnum.Progress]"
            [routerLinkActive]="(hasPayedAccess$ | async) === true ? 'sidenav-mobile__link--active' : ''"
            (click)="trackEvent('Navigation', 'Click Mon Compte')"
          >
            <svg-icon class="sidenav-mobile__link__icon" icon="stat" width="1.25em" height="1.25em"></svg-icon>
          </a>

          <a
            *ngIf="(hasPayedAccess$ | async) === true"
            class="sidenav-mobile__link"
            [routerLink]="['/', FeaturesRoutingEnum.Advices]"
            routerLinkActive="sidenav-mobile__link--active"
            (click)="trackEvent('Navigation', 'Click Conseils')"
          >
            <svg-icon
              class="sidenav-mobile__link__icon"
              icon="exp-information"
              width="1.25em"
              height="1.25em"
            ></svg-icon>
          </a>
        </nav>
      </mat-toolbar-row>
    </mat-toolbar>
  </ng-template>
</ng-container>
