import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { QueryParamsHiddenState } from '@stores/query-params-hidden/query-params-hidden.state';
import { SessionState } from '@stores/session/session.state';
import { isProfileFull } from '@wizbii-drive/models';
import { BugsnagService } from '@wizbii/angular-bugsnag';
import { WINDOW } from '@wizbii/angular-utilities';
import qs from 'qs';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class MustBeConnectedGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly errorHandlerService: BugsnagService,
    @Inject(WINDOW) private readonly window: any
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const queryParams = {
      ...QueryParamsHiddenState.getRequiredParams(route.queryParams),
      'ngsw-bypass': 'true',
    };
    const queryParamsStr = qs.stringify(queryParams);

    const redirect = `${environment.urls.driveApp}${this.window.location.pathname}?${queryParamsStr}`;
    const queryParamsAccount = {
      ...QueryParamsHiddenState.getAccountRequiredParams(route.queryParams),
      'app-id': environment.applicationId,
      redirect,
    };
    const queryParamsAccountStr = qs.stringify(queryParamsAccount);

    const accountSignin = `${environment.urls.account.signInBase}?${queryParamsAccountStr}`;

    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() =>
        combineLatest([
          this.store.selectOnce(SessionState.tokens).pipe(map((tokens) => !!tokens)),
          this.store.selectOnce(SessionState.profile).pipe(map((profile) => isProfileFull(profile))),
        ])
      ),
      map(([isLogged]) => {
        if (!isLogged) {
          this.errorHandlerService.sendError('Unauthorized route', 'warning');

          this.window.open(accountSignin, '_self');

          return false;
          // TODO: Redirect to 404
        }

        return true;
      })
    );
  }
}
