import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { NotificationType } from '@wizbii-drive/models';
import { NotificationWebservice } from '@wizbii-drive/webservices';
import { SvgIconModule } from '@wizbii/angular-ui';
import { trackEvent } from '@wizbii/tracking';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export enum NotificationDisplay {
  LaPoste = 'LaPoste',
  EVS = 'EVS',
}
export interface NotificationContentType {
  title: string;
  description: string;
  siteLabel: string;
  redirectUrl?: string;
  routerLink?: string[];
  externalRedirect: boolean;
  buttonLabel: string;
  buttonMobileLabel: string;
  icon: { name: string; width: string; height: string };
  iconClosed: { name: string; width: string; height: string };
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterLeaveAnim', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class NotificationComponent {
  trackEvent = trackEvent;
  trackCategory = 'Notification Partner';

  notificationContents: { [key: string]: NotificationContentType } = {
    LaPoste: {
      title: $localize`Prêt à passer le code ?`,
      description: $localize`Réservez rapidement une place d'examen dans les centres`,
      siteLabel: 'La Poste',
      routerLink: ['/', FeaturesRoutingEnum.Examen],
      externalRedirect: false,
      buttonLabel: $localize`Je réserve ma place !`,
      buttonMobileLabel: $localize`Je réserve`,
      icon: {
        name: 'women-laptop',
        width: '5.0625em',
        height: '7.875em',
      },
      iconClosed: {
        name: 'direction-sign',
        width: '1.4375em',
        height: '2.0625em',
      },
    },
    EVS: {
      title: $localize`Prêt à commencer la conduite ?`,
      description: $localize`Commencez vos heures de conduite avec notre partenaire `,
      siteLabel: 'En voiture Simone',
      redirectUrl: environment.urls.enVoitureSimoneNotificationUrl,
      externalRedirect: true,
      buttonLabel: $localize`Je découvre les offres`,
      buttonMobileLabel: $localize`Je découvre`,
      icon: {
        name: 'studs',
        width: '5.3125em',
        height: '6.5625em',
      },
      iconClosed: {
        name: 'stud',
        width: '1.75em',
        height: '2.0625em',
      },
    },
  };

  isMobile$: Observable<boolean>;

  displayType$: Observable<NotificationDisplay>;
  displayNotif$ = new BehaviorSubject<boolean>(true);

  constructor(
    readonly breakpointObserver: BreakpointObserver,
    private readonly notificationWebservice: NotificationWebservice
  ) {
    this.isMobile$ = breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      debounceTime(300),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.displayType$ = this.notificationWebservice.getNotifications().pipe(
      delay(500),
      map((notifications: string[]) => {
        if (notifications.findIndex((value) => value === NotificationType.EVS) >= 0) {
          this.trackEvent(this.trackCategory, 'Display', this.notificationContents[NotificationDisplay.EVS].siteLabel);
          return NotificationDisplay.EVS;
        } else if (notifications.findIndex((value) => value === NotificationType.LA_POSTE) >= 0) {
          this.trackEvent(
            this.trackCategory,
            'Display',
            this.notificationContents[NotificationDisplay.LaPoste].siteLabel
          );
          return NotificationDisplay.LaPoste;
        }
      })
    );
  }

  closeNotif(siteLabel: string): void {
    this.trackEvent(this.trackCategory, 'Close Notification', siteLabel);
    this.displayNotif$.next(false);
  }

  openNotif(siteLabel: string): void {
    this.trackEvent(this.trackCategory, 'Open Notification', siteLabel);
    this.displayNotif$.next(true);
  }
}

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule],
  exports: [NotificationComponent],
  declarations: [NotificationComponent],
})
export class NotificationModule {}
