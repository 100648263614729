export enum FeaturesRoutingEnum {
  Play = 'play',
  Resume = 'resume',
  Settings = 'settings',
  Profile = 'profile',
  Formula = 'formula',
  List = 'list',
  Result = 'result',
  Progress = 'progress',
  Lessons = 'lessons',
  Lesson = 'lesson',
  Advices = 'advices',
  Faq = 'faq',
  Chapter = 'chapter',
  Offers = 'offers',
  Checkout = 'checkout',
  NotFound = '404',
  Examen = 'exam',
  Neph = 'neph',
}
