import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { MobileMenuItem } from '@wizbii-drive/models';

export const menuItems: MobileMenuItem[] = [
  {
    title: $localize`Conseils`,
    link: `/${FeaturesRoutingEnum.Advices}`,
    hasRedirection: false,
  },
  {
    title: $localize`Mon profil`,
    link: `/${FeaturesRoutingEnum.Profile}`,
    hasRedirection: false,
  },
  {
    title: $localize`Mes factures`,
    link: `/${FeaturesRoutingEnum.Formula}`,
    hasRedirection: false,
  },
  {
    title: $localize`Confidentialité`,
    link: `https://account.${environment.apiDomain}/privacy`,
    hasRedirection: true,
  },
  {
    title: $localize`Sécurité & Connexion`,
    link: `https://account.${environment.apiDomain}/security`,
    hasRedirection: true,
  },
  {
    title: $localize`Notifications`,
    link: `https://account.${environment.apiDomain}/notifications`,
    hasRedirection: true,
  },
  {
    title: $localize`CGU`,
    link: `https://legal.${environment.apiDomain}/cgu`,
    hasRedirection: true,
  },
  {
    title: $localize`CGV`,
    link: `https://legal.${environment.apiDomain}/cgv/permii-app`,
    hasRedirection: true,
  },
];
