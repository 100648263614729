import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateToken } from '@ngxs/store';
import { iif, insertItem, updateItem } from '@ngxs/store/operators';
import { SetConsent, SetConsents } from '@stores/consents/consents.actions';
import { fromEvent } from 'rxjs';

import type { NgxsAfterBootstrap } from '@ngxs/store';
import type { Consent } from '@wizbii-drive/models';

const ConsentsStateToken = new StateToken<Consent[]>('consents');

@State({
  name: ConsentsStateToken,
  defaults: [],
})
@Injectable()
export class ConsentsState implements NgxsAfterBootstrap {
  static consent(key: string): any {
    return createSelector([ConsentsStateToken], (state: Consent[]) => state.find((consent) => consent.key === key));
  }

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  ngxsAfterBootstrap(ctx?: StateContext<any>): void {
    // Listen for all consents changed at once
    fromEvent(this.document, 'WizbiiGdpr.consentsChange').subscribe({
      next: ({ WizbiiGdpr: { consent } }) => {
        ctx.dispatch(new SetConsents(consent));
      },
    });

    // Listen for a consent changed on its own
    fromEvent(this.document, 'WizbiiGdpr.consentChange').subscribe({
      next: ({ WizbiiGdpr: { consent } }) => {
        ctx.dispatch(new SetConsent(consent));
      },
    });
  }

  @Action(SetConsents)
  setConsents(ctx: StateContext<Consent[]>, { consents }: SetConsents): void {
    const keyTest = Object.keys(consents)[0];

    if (Array.isArray(consents)) {
      ctx.setState(consents);
    } else if (Array.isArray(consents[keyTest])) {
      ctx.setState(Object.values(consents).flat());
    } else {
      ctx.setState(Object.keys(consents).map((key) => ({ key, value: consents[key] })) as any);
    }
  }

  @Action(SetConsent)
  setConsent(ctx: StateContext<Consent[]>, { consent }: SetConsent): void {
    ctx.setState(
      iif(
        (state) => !!state.find((stateConsent) => stateConsent.key === consent.key),
        updateItem((stateConsent) => stateConsent.key === consent.key, consent),
        insertItem(consent)
      )
    );
  }
}
