/* eslint-disable */

import { buildConfig } from '@config/build-config';
import { LangEnum, LocaleEnum } from '@wizbii-drive/models';
import { getRuntimeEnvVar } from '@wizbii/angular-utilities';

// @ts-ignore
const isProdBuild = buildConfig === 'production';
const version = process.env.VERSION;
const lang: LangEnum = LangEnum[process.env.LANG] || LangEnum.fr;
const locale: LocaleEnum = LocaleEnum[process.env.LOCALE] || LocaleEnum.fr_FR;

// Retrieve Ansible environment variables (injected during SSR), if any
let ansibleVars: Record<string, string> = {};
if (typeof window === 'object') {
  ansibleVars = window['env'] || {};
} else if (typeof global === 'object') {
  ansibleVars = global['ANGULAR_ENV'] || {};
}

// Determine deployment platform and domain from Ansible vars
const platform = ansibleVars.PLATFORM || 'local';
const domain = ansibleVars.WIZBII_DOMAIN;
const isDeployed = !!ansibleVars.PLATFORM;

// Determine API platform and domain from runtime vars
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const applicationId = 'permii-app';
const contactEmailKey = 'drive';
const accountRedirectUrl = platform === 'local' ? 'http://www.wizbii.me:4200' : `https://app.drive.${apiDomain}`;
const cookieDriveDomain = platform === 'local' ? 'localhost' : `.drive.${apiDomain}`;
const enVoitureSimoneNotificationUrl = isProdBuild
  ? 'https://www.envoituresimone.com/partenaires/wizbii-drive'
  : 'https://staging-front-evs.herokuapp.com/partenaires/wizbii';
const newSeriesDate = '2021-04-07T09:00:00+0000';
const showNewSeriesModal = false;

export const environment = {
  applicationId,
  contactEmailKey,
  platform,
  domain,
  isDeployed,
  apiPlatform,
  apiDomain,
  isProdBuild,
  version,
  lang,
  locale,
  cookieDriveDomain,
  bugsnagId: '60ed07107cf14bb79b5e68f367ca3d17',
  suggestionsSetId: 'wizbii-drive-france',
  newSeriesDate,
  showNewSeriesModal,
  api: {
    account: `https://account.api.${apiDomain}`,
    authentication: `https://auth.${apiDomain}`,
    contact: `https://contact.api.${apiDomain}`,
    content: `https://content-api.${apiDomain}`,
    consent: `https://consent.${apiDomain}`,
    file: `https://file.${apiDomain}`,
    googleStorage: 'https://storage.googleapis.com',
    imaginary: `https://imaginary.${apiDomain}`,
    monitor: `https://monitor.api.${apiDomain}`,
    permii: `https://permii.api.${apiDomain}`,
    wizbiiProfile: `https://profile-api.${apiDomain}`,
    place: `https://place.${apiDomain}`,
    suggestion: `https://suggestion.api.${apiDomain}`,
  },
  urls: {
    account: {
      base: `https://account.${apiDomain}`,
      signIn: `https://account.${apiDomain}/sign-in?app-id=${applicationId}&redirect=`,
      signInBase: `https://account.${apiDomain}/sign-in`,
      signUp: `https://account.${apiDomain}/sign-up?app-id=${applicationId}&redirect=`,
      activation: `https://account.${apiDomain}/sign-up/activation?app-id=${applicationId}&redirect=`,
      activationBase: `https://account.${apiDomain}/sign-up/activation`,
      logout: `https://account.${apiDomain}/logout?app-id=${applicationId}&redirect=`,
      lostPassword: `https://account.${apiDomain}/lost-password?redirect=${accountRedirectUrl}`,
    },
    money: {
      base: `https://money.${apiDomain}`,
    },
    driveApp: platform !== 'local' ? `https://app.drive.${apiDomain}` : 'http://localhost:4200',
    drivePublic: platform !== 'local' ? `https://drive.${apiDomain}` : 'http://localhost:4201',
    enVoitureSimoneNotificationUrl,
    googleStorage: 'https://storage.googleapis.com',
  },
  algolia: {
    applicationId: getRuntimeEnvVar('ALGOLIA_APP_ID'),
    apiKey: getRuntimeEnvVar('ALGOLIA_API_KEY'),
    index: {
      advices: `${apiPlatform}_content_article_wizbii-drive`,
      advicesCategories: `${apiPlatform}_content_facet_wizbii-drive_category`,
      drivingSchool: `${apiPlatform}_driving_school`,
      faq: `${apiPlatform}_content_article_faq-wizbii-drive`,
      faqCategories: `${apiPlatform}_content_facet_faq-wizbii-drive_category`,
    },
  },
  stripe: {
    key: getRuntimeEnvVar('STRIPE_PUBLIC_KEY'),
    url: 'https://js.stripe.com/v3/',
  },
  analytics: {
    gaConversionId: 'AW-874490256',
    waTrackingId: getRuntimeEnvVar('WIZBII_ANALYTICS_UAI'),
    gaTrackingId: getRuntimeEnvVar('WIZBII_ANALYTICS_UAI'),
    hotjar: {
      id: getRuntimeEnvVar('HOTJAR_ID'),
      version: '6',
    },
    yahoo: {
      projectId: '10000',
      pixelId: '10131589',
    },
    bing: {
      pixelId: '56286382',
    },
    facebookPixel: {
      id: getRuntimeEnvVar('FACEBOOK_PIXEL_ID'),
      version: '2.0',
    },
    snapchatPixel: {
      id: getRuntimeEnvVar('SNAPCHAT_PIXEL_ID'),
    },
    gtmId: 'GTM-NLFD893',
    tikTokId: 'C5KOHNL1T98720DVT9OG',
    juguiOfferId: '1410',
  },
  facebookLogin: {
    appId: '415255922695747',
    version: getRuntimeEnvVar('FACEBOOK_CONNECT_VERSION'),
  },
  googleLoginProvider: getRuntimeEnvVar('GOOGLE_CONNECT_KEY'),
  wizbiiFiles: getRuntimeEnvVar('WIZBII_FILES_GCS_BUCKET'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
if (!isProdBuild) {
  import('zone.js/dist/zone-error'); // Included with Angular CLI.
}
