import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _EventSourcePolyfill from 'event-source-polyfill';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@environment';

const EventSourcePolyfill = _EventSourcePolyfill.EventSourcePolyfill;

interface MercureCredentials {
  token: string;
  mercureUrl: string;
}

@Injectable({ providedIn: 'root' })
export class SseService {
  private readonly jwtHelperService: JwtHelperService;
  private credentials: MercureCredentials;

  constructor(private readonly zone: NgZone, private readonly http: HttpClient) {
    this.jwtHelperService = new JwtHelperService();
  }

  getServerSentEvent<T>(topic: string): Observable<MessageEvent<T>> {
    return this.getCredentials().pipe(
      switchMap(
        (credentials) =>
          new Observable<MessageEvent<T>>((observer) => {
            const url = new URL(credentials.mercureUrl);
            url.searchParams.append('topic', topic);
            url.searchParams.append('ngsw-bypass', 'true');

            const eventSource = this.getEventSource(url.toString(), credentials.token);
            eventSource.onmessage = (event: MessageEvent<T>) => {
              this.zone.run(() => {
                observer.next(event);
              });
            };

            eventSource.onerror = (event: Event) => {
              // EventSource.OPEN         no error, connection opened
              // EventSource.CONNECTING   connecting or error occured but browser is trying to reconnect
              // EventSource.CLOSED       error was fatal
              if (eventSource.readyState === EventSource.CLOSED) {
                this.zone.run(() => {
                  observer.error(event);
                });
              }
            };
          })
      )
    );
  }

  private getCredentials(): Observable<MercureCredentials> {
    const credentials = this.credentials;

    if (credentials && !this.jwtHelperService.isTokenExpired(credentials.token)) {
      return of(credentials);
    }

    return this.http
      .get<{ token: string }>(`${environment.api.permii}/_credentials/mercure`, { observe: 'response' })
      .pipe(
        map((response) => {
          const hubUrl = response.headers.get('Link').match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1];

          return {
            token: response.body.token,
            mercureUrl: hubUrl,
          };
        }),
        tap((newCredentials) => (this.credentials = newCredentials))
      );
  }

  private getEventSource(url: string, token?: string): any {
    return new EventSourcePolyfill(url, {
      headers: { ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    });
  }
}
