<mat-progress-bar
  class="mat-progress-bar--only-progress mat-progress-bar--fixed-top progress"
  mode="determinate"
  [value]="loadingBarService.progress$ | async"
></mat-progress-bar>

<wiz-header
  *ngIf="(isMobile$ | async) === false && showInterservicesHeader$ | async"
  class="wiz-header"
  current="mobilite"
  [attr.app]="appId"
  cookiekey="wizbii_tokens"
  [attr.apidomain]="apiDomain"
  [attr.accountredirect]="driveAppUrl"
  [attr.accountredirectlogout]="drivePublicUrl"
></wiz-header>

<router-outlet></router-outlet>

<app-sprite></app-sprite>

<ng-template #updateApp>
  <div i18n>Il y a une nouvelle version de Wizbii Drive&nbsp;!</div>
  <div i18n>Nous vous conseillons de mettre à jour votre application.</div>
  <div class="wizbii-snackbar__actions">
    <button mat-button class="btn-clean" class="wizbii-snackbar__actions--cancel" (click)="cancelReload()" i18n>
      Annuler
    </button>
    <button mat-button class="btn-clean" class="wizbii-snackbar__actions--submit" (click)="confirmReload()" i18n>
      Recharger
    </button>
  </div>
</ng-template>
