import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  ErrorHandler,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  RendererStyleFlags2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { trackingConfig as baseTrackingConfig } from '@config/tracking-config';
import { SseService } from '@core/services/sse.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Network } from '@ngx-pwa/offline';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { SetQueryParamHidden } from '@stores/query-params-hidden/query-params-hidden.actions';
import { SessionState } from '@stores/session/session.state';
import { Coupon, Profile } from '@wizbii-drive/models';
import { CustomMetaService, TimeoneService } from '@wizbii-drive/services';
import { PaymentWebservice, SponsoringAdvantage, SponsorshipWebservice } from '@wizbii-drive/webservices';
import { ContactModalService } from '@wizbii/angular-ui';
import { WINDOW } from '@wizbii/angular-utilities';
import { setupTracking, track } from '@wizbii/tracking';
import { sendWebVitals, WebVitalsOptions, WebVitalsParams } from '@wizbii/utils';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, concat, EMPTY, fromEvent, interval, Observable, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  @HostBinding('class.fix-ios-bug')
  get fixIosBug(): boolean {
    const isIOS =
      /iPad|iPhone|iPod/.test(this.window.navigator.platform) ||
      (this.window.navigator.platform === 'MacIntel' && this.window.navigator.maxTouchPoints > 1);
    return this.platform.IOS || isIOS;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  subscriptions: Subscription[] = [];
  snackBarRef: MatSnackBarRef<EmbeddedViewRef<any>>;

  @ViewChild('updateApp')
  updateAppTemplate: TemplateRef<any>;

  track = track;
  trackingIsSetup = false;

  appId = environment.applicationId;
  apiDomain = environment.apiDomain;
  driveAppUrl = environment.urls.driveApp;
  drivePublicUrl = environment.urls.drivePublic;

  @Select(SessionState.hasPayedAccess)
  hasPayedAccess$: Observable<boolean>;

  isMobile$: Observable<boolean>;
  showInterservicesHeader$: Observable<boolean>;

  private readonly destroyed$ = new Subject<void>();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(
    readonly loadingBarService: LoadingBarService,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
    @Inject(WINDOW) private readonly window: any,
    @Inject(ErrorHandler) errorHandler: BugsnagErrorHandler,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly metaService: CustomMetaService,
    private readonly platform: Platform,
    private readonly swUpdate: SwUpdate,
    private readonly cdr: ChangeDetectorRef,
    private readonly appRef: ApplicationRef,
    private readonly gtmService: GoogleTagManagerService,
    private readonly cookieService: CookieService,
    private readonly sponsorshipWebservice: SponsorshipWebservice,
    private readonly paymentWebservice: PaymentWebservice,
    private readonly sseService: SseService,
    breakpointObserver: BreakpointObserver,
    private readonly store: Store,
    readonly contactModalService: ContactModalService,
    _: Network,
    private readonly timeoneService: TimeoneService
  ) {
    this.isMobile$ = breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    if (isPlatformServer(platformId)) {
      return;
    }

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(this.window, 'touchstart', { passive: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.hoverEnabled = false;
        },
      });

    // Provide user data to Bugsnag
    store
      .select(SessionState.user)
      .pipe(filter((user) => !!user))
      .subscribe({
        next: (user) => {
          errorHandler.bugsnagClient.setUser(undefined, user?.username ? user.username : undefined);
        },
      });

    store
      .select(SessionState.isInitialized)
      .pipe(
        filter((isInitialized) => isInitialized),
        switchMap(() => store.selectOnce(SessionState.tokens)),
        map((tokens) =>
          tokens
            ? {
                ...baseTrackingConfig,
                consentWidget: {
                  ...baseTrackingConfig.consentWidget,
                  auth: {
                    type: 'jwt',
                    token: tokens.token,
                  },
                },
              }
            : baseTrackingConfig
        ),
        switchMap((trackingConfig) =>
          store.selectOnce(SessionState.profile).pipe(map((profile) => ({ trackingConfig, profile })))
        ),
        map(({ trackingConfig, profile }) =>
          profile
            ? {
                ...trackingConfig,
                consentWidget: {
                  ...trackingConfig.consentWidget,
                  fullName:
                    !!profile && !!profile.firstName && !!profile.lastName
                      ? `${profile.firstName} ${profile.lastName}`
                      : !!profile && !!profile.firstName
                      ? profile.firstName
                      : null,
                },
              }
            : trackingConfig
        )
      )
      .subscribe({
        next: (trackingConfig) => {
          setupTracking(trackingConfig).then(() => {
            this.subscriptions.push(
              store
                .select(SessionState.info)
                .pipe(
                  map((token) => {
                    const { 'user-id': userId } = { 'user-id': undefined, ...token };
                    return userId;
                  }),
                  distinctUntilChanged(),
                  tap((userId) => {
                    window['wa']('set', 'wizbiiUserId', userId);
                  }),
                  switchMap(() => store.selectOnce(SessionState.tokens))
                )
                .subscribe({
                  next: (tokens) => {
                    if (tokens) {
                      window.WizbiiGdpr.setAuth({
                        type: 'jwt',
                        token: tokens.token,
                      });
                    }

                    if (!this.trackingIsSetup) {
                      const { href: url, pathname, search, hash } = window.location;
                      const page = pathname + search + hash;
                      track('pageview', { url, page });

                      if (this.cookieService.check('timeone_session')) {
                        this.timeoneService.addDot({ event: 'pageview' });
                      }

                      this.trackingIsSetup = true;
                    }
                  },
                })
            );
          });
        },
      });

    this.showInterservicesHeader$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        return (
          !event.url.includes(FeaturesRoutingEnum.Play) &&
          !event.url.includes(FeaturesRoutingEnum.Resume) &&
          !event.url.includes(FeaturesRoutingEnum.Checkout)
        );
      })
    );

    combineLatest([
      this.route.queryParamMap.pipe(
        map((params) => params.get('coupon')),
        filter((coupon) => Boolean(coupon)),
        switchMap((coupon) => this.paymentWebservice.getCoupon(coupon)),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      ),
      store.selectOnce(SessionState.profile).pipe(
        filter((profile) => Boolean(profile)),
        switchMap((profile: Profile) => this.sponsorshipWebservice.getCoupon(profile.id))
      ),
    ])
      .pipe(
        map(([coupon, sponsoringAdvantage]: [Coupon, SponsoringAdvantage]) => {
          if (!sponsoringAdvantage.coupon) {
            return coupon;
          }

          if (!sponsoringAdvantage.coupon.percentOff) {
            return coupon;
          }

          if (coupon.percentOff > sponsoringAdvantage.coupon.percentOff) {
            return coupon;
          }

          return sponsoringAdvantage.coupon;
        })
      )
      .subscribe({
        next: (coupon: Coupon) => {
          store.dispatch(new SetQueryParamHidden('coupon', coupon.id));
        },
      });

    this.route.queryParamMap
      .pipe(
        map((params) => params.get('utm_source')),
        filter((utmSource) => !!utmSource),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      )
      .subscribe({
        next: (utmSource) => {
          if (utmSource === 'timeone') {
            if (!this.cookieService.check('timeone_session')) {
              const cookieTimeoneDate = new Date();
              cookieTimeoneDate.setDate(cookieTimeoneDate.getDate() + 7);

              this.cookieService.set('timeone_session', 'true', cookieTimeoneDate, '/', environment.cookieDriveDomain);
            }

            this.timeoneService.initGlobals();
          }
        },
      });

    this.route.queryParamMap
      .pipe(
        map((params) => params.get('contact')),
        filter((contact) => !!contact),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true }),
        switchMap(() =>
          this.contactModalService.openDialog({
            appId: environment.applicationId,
            contactEmailKey: environment.contactEmailKey,
            locale: environment.locale,
          })
        )
      )
      .subscribe();

    this.route.queryParamMap
      .pipe(
        map((params) => params.get('sponsoringCode')),
        filter((code) => !!code),
        switchMap((coupon) => this.sponsorshipWebservice.associateSponsor(coupon)),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 403) {
            return EMPTY;
          }
          return throwError(err);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        distinctUntilChanged()
      )
      .subscribe();

    if (this.cookieService.check('timeone_session')) {
      this.timeoneService.initGlobals();
    }

    this.gtmService.addGtmToDom();
  }

  ngOnInit(): void {
    this.metaService.updateOrAddTag({
      property: 'fb:app_id',
      content: environment.facebookLogin.appId,
    });

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.document.documentElement.lang === '') {
      this.document.documentElement.lang = environment.lang;
    }

    this.sseService.getServerSentEvent('/drive-app/version').subscribe({
      next: () => {
        this.openSnackBarUpdate();
      },
      error: (error) => {
        /* eslint-disable no-console */
        console.error(error);
      },
    });

    if (this.swUpdate.isEnabled) {
      concat(this.appRef.isStable.pipe(first((isStable) => isStable === true)), interval(6 * 60 * 60 * 1000)).subscribe(
        { next: () => this.swUpdate.checkForUpdate() }
      );

      this.swUpdate.available.subscribe({
        next: () => {
          this.openSnackBarUpdate();
        },
      });
    }

    const routerState = this.store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.apiDomain,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.platformId),
      };
      sendWebVitals(params, options);
    }
  }

  private openSnackBarUpdate(): void {
    const currentHour = new Date().getHours();

    if (currentHour < 18 || currentHour > 19) {
      return;
    }

    const blacklistUpdateRoute: string[] = [
      FeaturesRoutingEnum.Checkout,
      FeaturesRoutingEnum.Play,
      FeaturesRoutingEnum.Resume,
    ];

    if (blacklistUpdateRoute.some((route) => this.router.url.includes(route))) {
      return;
    }

    this.snackBarRef = this.snackBar.openFromTemplate(this.updateAppTemplate, {
      panelClass: 'wizbii-snackbar',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
    this.cdr.detectChanges();
  }

  cancelReload(): void {
    this.snackBarRef.dismiss();
  }

  confirmReload(): void {
    this.window.location.reload();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
        next: () => {
          if (this.trackingIsSetup) {
            const { href: url, pathname, search, hash } = this.window.location;
            const page = pathname + search + hash;
            this.track('pageview', { url, page });

            if (this.cookieService.check('timeone_session')) {
              this.timeoneService.addDot({ event: 'pageview' });
            }
          }
        },
      })
    );

    fromEvent(this.document, 'WizbiiGdpr.toggleDialogVisibility')
      .pipe(debounceTime(300))
      .subscribe({
        next: () => {
          this.renderer.setStyle(
            this.renderer.parentNode(this.document.querySelector("[class^='gdpr_overlay']")),
            'transform',
            'none',
            RendererStyleFlags2.DashCase | RendererStyleFlags2.Important
          );
        },
      });
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): any {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
