import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { NephStateModel, SetEditMode, SetNephObject } from '@stores/neph/neph.actions';
import { NephWebservice } from '@wizbii-drive/webservices';

import type { NephObject } from '@wizbii-drive/models';
import type { NgxsAfterBootstrap } from '@ngxs/store';

const NephStateToken = new StateToken<NephStateModel>('neph');

@State({
  name: NephStateToken,
  defaults: {
    nephObject: undefined,
    editMode: false,
  },
})
@Injectable()
export class NephState implements NgxsAfterBootstrap {
  @Selector([NephState])
  static nephObject(state: NephStateModel): NephObject {
    return state.nephObject;
  }

  @Selector([NephState])
  static currentPlace(state: NephStateModel): string {
    return state.nephObject?.subscription.currentPlace;
  }

  @Selector([NephState])
  static editMode(state: NephStateModel): boolean {
    return state.editMode;
  }

  constructor(private readonly nephWebservice: NephWebservice) {}

  ngxsAfterBootstrap(ctx: StateContext<NephStateModel>): void {
    this.nephWebservice.getSubscription().subscribe({
      next: (nephObject) => this.updateSubscription(ctx, new SetNephObject(nephObject)),
    });
  }

  @Action(SetNephObject)
  updateSubscription(ctx: StateContext<NephStateModel>, { nephObject }: SetNephObject): void {
    ctx.patchState({ nephObject });
  }

  @Action(SetEditMode)
  updateEditMode(ctx: StateContext<NephStateModel>, { editMode }: SetEditMode): void {
    ctx.patchState({ editMode });
  }
}
