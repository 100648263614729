import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfferHeaderModule } from '@commons/offer-header/offer-header.component';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/components/core/core.component';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { OfflineInterceptor } from '@core/interceptors/offline.interceptor';
import { ngxsConfig } from '@core/ngxs.config';
import { JwtService } from '@core/services/jwt.service';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ConsentsState } from '@stores/consents/consents.state';
import { MobileState } from '@stores/mobile/mobile.state';
import { QueryParamsHiddenState } from '@stores/query-params-hidden/query-params-hidden.state';
import { SessionState } from '@stores/session/session.state';
import { WizbiiRouterStateSerializer } from '@wizbii-drive/services';
import { CONSENT_API_CONFIG, ConsentWebservice } from '@wizbii-drive/webservices';
import { AppBrowserViewportScroller } from '@wizbii-drive/widgets';
import { ALGOLIA_SERVICE_TOKEN, AlgoliaParamsInterface } from '@wizbii/algolia';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { DataStorageService, ImageService, WINDOW_PROVIDERS } from '@wizbii/angular-utilities';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { FILE_API_CONFIG, FileWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

@NgModule({
  imports: [QuicklinkModule],
  exports: [QuicklinkModule],
})
export class QuicklinkSharedModule {}

const algoliaParams: AlgoliaParamsInterface = {
  applicationId: environment.algolia.applicationId,
  apiKey: environment.algolia.apiKey,
  options: {},
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,

    QuicklinkSharedModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
    NgxsModule.forRoot([SessionState, ConsentsState, QueryParamsHiddenState, MobileState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.isProdBuild,
      registrationStrategy: 'registerWhenStable',
    }),
    StorageModule.forRoot({ LSPrefix: 'wizbii-drive_app_', IDBDBName: 'wizbii-drive_app_ngStorage', IDBNoWrap: true }),
    ScrollToModule.forRoot(),
    HttpClientModule,

    MatSnackBarModule,
    MatButtonModule,
    MatProgressBarModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    OfferHeaderModule,

    FeaturesModule,
    PlatformModule,

    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Wizbii Drive App', maxAge: 30 }),
  ],
  providers: [
    DataStorageService,
    CookieService,
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },

    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },

    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },

    { provide: ALGOLIA_SERVICE_TOKEN, useValue: algoliaParams },

    { provide: ViewportScroller, useClass: AppBrowserViewportScroller },

    WINDOW_PROVIDERS,
    { provide: ImageService, useValue: new ImageService(environment.api.imaginary) },

    {
      provide: CONSENT_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.consent,
      },
    },
    ConsentWebservice,

    { provide: 'googleTagManagerId', useValue: environment.analytics.gtmId },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.urls.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    FileWebservice,
  ],
  declarations: [CoreComponent, SpriteComponent],
  bootstrap: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
