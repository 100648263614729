import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateToken } from '@ngxs/store';
import {
  RemoveMultipleQueryParamHidden,
  SetMultipleQueryParamHidden,
  SetQueryParamHidden,
} from '@stores/query-params-hidden/query-params-hidden.actions';

export type QueryParamsHiddenStateModel = { [key: string]: string };

const QueryParamsHiddenStateToken = new StateToken<QueryParamsHiddenStateModel>('queryParamsHidden');

@State({
  name: QueryParamsHiddenStateToken,
  defaults: {},
})
@Injectable()
export class QueryParamsHiddenState {
  static readonly FROM_REDIRECT = 'from_redirect';

  static queryParam(key: string): (state: QueryParamsHiddenStateModel) => string {
    return createSelector([QueryParamsHiddenState], (state: QueryParamsHiddenStateModel) => state[key]);
  }

  static queryParams(containKey: string): (state: QueryParamsHiddenStateModel) => { [key: string]: string } {
    return createSelector([QueryParamsHiddenState], (state: QueryParamsHiddenStateModel) =>
      Object.keys(state || {}).reduce((carry, key) => {
        if (key.includes(containKey)) {
          return {
            ...carry,
            [key]: state[key],
          };
        }

        return carry;
      }, {})
    );
  }

  @Action(SetQueryParamHidden)
  setQueryParamHidden(
    ctx: StateContext<QueryParamsHiddenStateModel>,
    { key: keyToSet, value }: SetQueryParamHidden
  ): void {
    const state = ctx.getState();

    if (!value) {
      ctx.setState(
        Object.keys(state || {}).reduce((carry, key) => {
          if (key !== keyToSet) {
            carry[key] = state[key];
          }
          return carry;
        }, {})
      );
    } else {
      ctx.patchState({ [keyToSet]: value });
    }
  }

  @Action(SetMultipleQueryParamHidden)
  setMultipleQueryParamHidden(
    ctx: StateContext<QueryParamsHiddenStateModel>,
    { queryParams }: SetMultipleQueryParamHidden
  ): void {
    ctx.patchState(
      Object.keys(queryParams || {}).reduce((carry, key) => {
        if (queryParams[key]) {
          carry[key] = queryParams[key];
        }
        return carry;
      }, {})
    );
  }

  @Action(RemoveMultipleQueryParamHidden)
  removeMultipleQueryParamHidden(
    ctx: StateContext<QueryParamsHiddenStateModel>,
    { containKey }: RemoveMultipleQueryParamHidden
  ): void {
    const state = ctx.getState();

    ctx.setState(
      Object.keys(state || {}).reduce((carry, key) => {
        if (!key.includes(containKey)) {
          carry[key] = state[key];
        }
        return carry;
      }, {})
    );
  }

  static getRequiredParams(queryParams: { [key: string]: string }): { [key: string]: string } {
    return Object.keys(queryParams || {}).reduce((carry, key) => {
      if (
        key === 'coupon' ||
        key === 'respMail' ||
        key === 'utm_source' ||
        key === 'sponsoringCode' ||
        key === 'city'
      ) {
        return {
          ...carry,
          [key]: queryParams[key],
        };
      }

      return carry;
    }, {});
  }

  static getAccountRequiredParams(queryParams: { [key: string]: string }): { [key: string]: string } {
    return Object.keys(queryParams || {}).reduce((carry, key) => {
      if (key.includes('utm_') || key === 'coupon') {
        return {
          ...carry,
          [key]: queryParams[key],
          ...(key === 'coupon' ? { context: 'wizbii-drive-influencer' } : {}),
        };
      }

      return carry;
    }, {});
  }
}
