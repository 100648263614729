import { environment } from '@environment';
import { BugsnagGroupingRule } from '@wizbii/angular-bugsnag';

const MESSAGE_GROUPS: BugsnagGroupingRule[] = [
  { regex: /(Loading chunk) [0-9]+ (failed)[^]*/, msg: '$1 $2' },
  { regex: /(\/profiles)\/[^/]+(.*):/, msg: '$1/<profile-id>$2:' },
  { regex: /(\/user)\/[^/]+(.*):/, msg: '$1/<user-id>$2:' },
  { regex: /(\/location\?name=)[^:]+/, msg: '$1<keyword>' },
  { regex: /(\/location)\/[^:]+/, msg: '$1/<location-id>' },
  { regex: /(\/m-api.wizbii.com\/v1\/i)\/[^:]+/, msg: '$1/<media-id>' },
  { regex: /(animation trigger ".+" has failed to build)[^]*/, msg: '$1' },
  { regex: /(JSONP request)[^]*(failed)$/, msg: '$1 $2' },
];

export const bugsnagConfig = {
  apiKey: environment.bugsnagId,
  appVersion: environment.version,
  autoTrackSessions: false,
  releaseStage: environment.platform,
  isDeployed: environment.isDeployed,
  groupingRules: MESSAGE_GROUPS,
};
