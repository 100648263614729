import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  NgModule,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { MobileHeaderModule } from '@commons/mobile-header/mobile-header.component';
import { NotificationModule } from '@commons/notification/notification.component';
import { OfferHeaderComponent, OfferHeaderModule } from '@commons/offer-header/offer-header.component';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { SeriesRoutingEnum } from '@features/list/list-routing.enum';
import { NephRoutingEnum } from '@features/neph/neph-routing.enum';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Logout } from '@stores/session/session.actions';
import { SessionState } from '@stores/session/session.state';
import { Access, Accessorder, Mode, Profile, SerieListItem, Steps } from '@wizbii-drive/models';
import { BreakpointsService } from '@wizbii-drive/services';
import { PaymentWebservice, SerieRunWebservice, SerieWebservice } from '@wizbii-drive/webservices';
import { bounceAnimation, catchNotFound } from '@wizbii-drive/widgets';
import { SvgIconModule } from '@wizbii/angular-ui';
import { ModalProfile } from '@wizbii/angular-ui/lib/contact-modal/contact-modal';
import { WINDOW } from '@wizbii/angular-utilities';
import { UserOverview } from '@wizbii/models';
import { trackEvent } from '@wizbii/tracking';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, take, tap } from 'rxjs/operators';
import { NephState } from '@stores/neph/neph.state';

const animationBaseParam = { duration: 300 };
const animationFadeParam = { ...animationBaseParam, translate: '25%' };

@Component({
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(animationFadeParam),
    fadeOutDownOnLeaveAnimation(animationFadeParam),
    bounceAnimation(),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLayoutComponent implements AfterViewInit, OnDestroy {
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  SeriesRoutingEnum = SeriesRoutingEnum;
  NephRoutingEnum = NephRoutingEnum;

  platform$: Observable<string>;
  isLandscape$: Observable<boolean>;
  displayBtnNewSerie$: Observable<boolean>;
  offersHeaderRemHeight$: Observable<number>;
  serieFree$: Observable<SerieListItem>;
  seriesOfficialFinished$: Observable<SerieListItem[]>;
  accessesOrders$: Observable<Accessorder[]>;
  contactProfile: ModalProfile;

  displayModal = false;

  @Select(SessionState.profile)
  profile$: Observable<Profile>;

  @Select(SessionState.accesses)
  accesses$: Observable<Access[]>;

  @Select(SessionState.hasPayedAccess)
  hasPayedAccess$: Observable<boolean>;

  @Select(SessionState.user)
  user$: Observable<UserOverview>;

  @ViewChild(OfferHeaderComponent)
  offersHeader: OfferHeaderComponent;

  appId = environment.applicationId;
  apiDomain = environment.apiDomain;
  contactEmailKey = environment.contactEmailKey;
  currentLocale = environment.locale;

  trackEvent = trackEvent;

  private observer: MutationObserver;

  get offersHeaderRemHeight(): number {
    const insetTopRem = this.getSafeAreaInsetTopRem();

    return this.offersHeader && this.offersHeader.nativeElement
      ? this.offersHeader.nativeElement.clientHeight / 16 + insetTopRem
      : insetTopRem;
  }

  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  constructor(
    breakpointObserver: BreakpointObserver,
    private readonly breakpointsService: BreakpointsService,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly serieWebservice: SerieWebservice,
    private readonly serieRunWebservice: SerieRunWebservice,
    private readonly paymentWebservice: PaymentWebservice,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(WINDOW) private readonly window: any
  ) {
    this.platform$ = combineLatest([
      breakpointObserver.observe([Breakpoints.Handset]),
      breakpointObserver.observe([Breakpoints.Tablet]),
      breakpointObserver.observe([Breakpoints.Web]),
    ]).pipe(
      map(([handset, tablet]) => (handset.matches ? 'mobile' : tablet.matches ? 'tablet' : 'web')),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.serieFree$ = this.serieRunWebservice.findOfficial('exam').pipe(
      catchNotFound(router, [FeaturesRoutingEnum.NotFound]),
      map((series) => series.find((serie) => (serie.packageIds || []).length === 0)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.offersHeaderRemHeight$ = fromEvent(this.window, 'resize', { passive: true }).pipe(
      startWith(this.offersHeaderRemHeight),
      debounceTime(300),
      map(() => this.offersHeaderRemHeight)
    );

    this.isLandscape$ = breakpointObserver.observe('(orientation: landscape)').pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.displayBtnNewSerie$ = this.store.select(RouterState.state).pipe(
      map((state: any) => !!state.data?.displayBtnNewSerie),
      tap(() => (this.displayModal = false)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    combineLatest([this.profile$, this.user$])
      .pipe(
        take(1),
        filter(([profile, user]) => !!profile && !!user)
      )
      .subscribe({
        next: ([profile, user]) =>
          (this.contactProfile = {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            phone: profile?.phoneNumber,
            email: user?.username,
          }),
      });

    this.seriesOfficialFinished$ = this.serieRunWebservice.findOfficial('exam').pipe(
      distinctUntilChanged(),
      map((series) => series.filter((serie) => serie.status === 'finished' && serie.type === 'official')),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.accessesOrders$ = combineLatest([
      this.paymentWebservice.findOrders().pipe(distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true })),
      this.accesses$,
    ]).pipe(
      filter(([orders, accesses]) => !!accesses && !!orders),
      map(([orders, accesses]) => {
        return (accesses || []).map((access) => {
          const orderAccess = (orders || []).find((order) => access.orderId === order.id);

          return {
            order: orderAccess,
            access,
            hasAccess: access && access.accessEnd ? access.accessEnd * 1000 >= Date.now() : false,
          };
        });
      }),
      map((accessOrders) => accessOrders.filter((accessOrder) => accessOrder.hasAccess)),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  ngAfterViewInit(): void {
    this.initMutationObserver();
  }

  initMutationObserver(): void {
    if (!('MutationObserver' in this.window)) {
      return; // skip SSR and old browsers
    }

    if (this.offersHeader) {
      this.observer = new MutationObserver(() => (this.offersHeaderRemHeight$ = of(this.offersHeaderRemHeight)));
      this.observer.observe(this.offersHeader.nativeElement, { childList: true });
    }
  }

  get shouldShowNephEntry(): boolean {
    return this.store.selectSnapshot(NephState.currentPlace) !== Steps.Dashboard;
  }

  get nephURL(): string[] {
    return this.store.selectSnapshot(SessionState.hasPayedNeph)
      ? ['/', FeaturesRoutingEnum.Neph, NephRoutingEnum.Status]
      : ['/', FeaturesRoutingEnum.Neph];
  }

  getSafeAreaInsetTopRem(): number {
    if (CSS.supports('padding-bottom: env(safe-area-inset-top)')) {
      const div = this.document.createElement('div');
      div.style.paddingTop = 'env(safe-area-inset-top, 0.75rem)';
      this.document.body.appendChild(div);
      const calculatedPadding = parseInt(this.window.getComputedStyle(div).paddingTop, 10);
      this.document.body.removeChild(div);
      return calculatedPadding / 16;
    }
  }

  logout(): void {
    this.store.dispatch(new Logout());
    this.trackEvent('Navigation', 'Click Deconnexion');
  }

  toggleModal(): void {
    this.displayModal = !this.displayModal;
    this.cdr.detectChanges();
  }

  startNewSerie(mode: Mode): void {
    this.trackEvent('Navigation', 'Click Nouvelle Série', this.router.url);

    this.serieWebservice.getRandomSerieId().subscribe({
      next: (serieId) => {
        this.router.navigate([FeaturesRoutingEnum.Play, mode, serieId], { state: { currentRoute: this.router.url } });
      },
    });

    this.trackEvent('Navigation', `Click Commencer ${mode === 'exam' ? 'Exam' : 'Entrainement'}`, this.router.url);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    PlatformModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    SvgIconModule,
    MatToolbarModule,
    MobileHeaderModule,
    OfferHeaderModule,
    NotificationModule,
  ],
  declarations: [DashboardLayoutComponent],
  exports: [DashboardLayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardLayoutModule {}
