<header class="mobile-header">
  <a *ngIf="(isDashboard$ | async) === true && (isSubmenuOpen$ | async) === undefined" [routerLink]="['/']">
    <svg-icon icon="logo" width="7.8125em" height="2em"></svg-icon>
  </a>

  <h1
    *ngIf="
      (isDashboard$ | async) === false || ((isDashboard$ | async) === true && (isSubmenuOpen$ | async) !== undefined)
    "
    class="title"
  >
    {{ title$ | async }}
  </h1>

  <ng-container *ngIf="notifications$ | async as notifications">
    <button
      *ngIf="notifications.length"
      type="button"
      class="notifications-icon btn-clean"
      [class.notifications-icon--open]="(isSubmenuOpen$ | async) === SubMenuState.Notifications"
      aria-label="Bouton d'ouverture des notifications"
      (click)="openCloseSubMenu(SubMenuState.Notifications)"
    >
      <svg-icon icon="bell" width="1.5em"></svg-icon>
      <span *ngIf="displayNotificationsRedDot" class="notifications-icon__nb-notifications"></span>
    </button>
  </ng-container>

  <button
    class="mobile-header__menu"
    [class.mobile-header__menu--active]="(isSubmenuOpen$ | async) === SubMenuState.MobileMenu"
    (click)="openCloseSubMenu(SubMenuState.MobileMenu)"
    aria-label="Boutton permettant d'ouvrir ou fermer le menu mobile"
  >
    <svg-icon
      *ngIf="(isSubmenuOpen$ | async) !== SubMenuState.MobileMenu"
      icon="mobile-menu"
      width="1.25em"
      height="1.25em"
    ></svg-icon>
    <svg-icon
      *ngIf="(isSubmenuOpen$ | async) === SubMenuState.MobileMenu"
      icon="exp-cross"
      width="1.5em"
      height="1.5em"
    ></svg-icon>
  </button>
</header>

<div *ngIf="subtitle$ | async as subtitle" class="mobile-subheader">
  <a
    class="mobile-subheader__icon"
    [routerLink]="subtitle.backLink"
    (click)="trackEvent(subtitle.name, 'Click Retour')"
  >
    <svg-icon icon="exp-act-left" width="1.5em"></svg-icon>
  </a>

  <h2 class="subtitle">{{ subtitle.name }}</h2>
</div>

<section class="mobile-menu" @slide *ngIf="isSubmenuOpen$ | async as isSubmenuOpen">
  <drive-mobile-menu
    @slide
    *ngIf="isSubmenuOpen === SubMenuState.MobileMenu"
    [mobileMenuItems]="menuItems"
    (clickItem)="openCloseSubMenu(SubMenuState.MobileMenu); handleClick($event)"
  ></drive-mobile-menu>

  <drive-notifications
    @slide
    *ngIf="isSubmenuOpen === SubMenuState.Notifications"
    [notifications]="notifications$ | async"
    (removeNotification)="removeNotification($event)"
    (clickOutside)="openCloseSubMenu(SubMenuState.Notifications)"
    (clickNotification)="openCloseSubMenu(SubMenuState.Notifications)"
  ></drive-notifications>
</section>
